export default class Product {
	constructor({id, name, image, quantity, size, price, savings = 0}) {
		this.id = id,
		this.name = name;
		this.image = image;
		this.quantity = quantity;
		this.size = size;
		this.price = price;
		this.savings = savings;
	}
}