export default class FacebookAuthConfigs {
	constructor(configs) {
		this.facebookClientId = configs.VUE_APP_FACEBOOK_CLIENT_ID;
		this.facebookRedirectUri = configs.VUE_APP_FACEBOOK_REDIRECT_URI;
		this.facebookState = configs.VUE_APP_FACEBOOK_STATE;
		this.facebookResponseType = configs.VUE_APP_FACEBOOK_RESPONSE_TYPE;
		this.facebookAuthorizationUrl =
			`${configs.VUE_APP_FACEBOOK_AUTHORIZATION_URL_BASE}?` +
			`client_id=${this.facebookClientId}&` +
			`redirect_uri=${encodeURIComponent(this.facebookRedirectUri)}&` +
			`response_type=${this.facebookResponseType}&` 
	}

	getFacebookAuthCode(state) {
		window.location.href = this.facebookAuthorizationUrl +`state=${state}`;
	}
}