<template>
	<div class="container-md py-5">
		<div class="row d-flex justify-content-center">
			<div 
				class=" col-xl-5 col-sm-8 col-11 rounded-5 bg-white py-3" 
			>
				<NavigativeElements 
					:tabs="tabData" 
					:initial-tab="initialTab"
				/>
			</div>
		</div>
	</div>
</template>
<script>
import NavigativeElements from '@/common/components/NavigativeElements.vue'
import SignUpPage from './SignUpPage.vue';
import SignInPage from './SignInPage.vue';
import { AuthService } from '@/Authentication/service/authenticationService';
import {  AuthTypeEnum, AuthModeEnum } from '@/config/formuloWebConstants';
export default {
	components: {
		NavigativeElements
	},
	data() {
		return {
			activeButton: 'Sign In',
			tabData: [
				{
					name: 'Sign In',
					title: 'Sign In',
					component: SignInPage
				},
				{
					name: 'Sign Up',
					title: 'Sign Up',
					component: SignUpPage
				}
			]
		}
	},
	computed: {
		initialTab() {
			return this.$route.query.tab || 'Sign In';
		}
	},
	async mounted(){
		const params = new URLSearchParams(window.location.search);
		const authCode = params.get("code");
		const state = params.get("state");

		if(state && this.verifyState(state)){
			const mode = this.$cookies.getCookie('auth_name')
			const type = this.$cookies.getCookie('auth_type')

			const authService = new AuthService()

			if(mode === AuthModeEnum.GOOGLE_AUTH){
				this.initiateGoogleAuthentication(type, authCode, authService).then(
					(jwt_token) => {
						if(jwt_token)
							this.$cookies.setCookie("formulo_jwt_token", jwt_token)
						this.removeAuthCookies()
					}).catch(
					err =>
					{
						err.message = "Google authentication failed".
							this.removeAuthCookies()
					})
				
			}
			else if(mode === AuthModeEnum.FACEBOOK_AUTH) {
				this.initiateFacebookAuthentication(type, authCode, authService).then(
					(jwt_token) => {
						if(jwt_token)
							this.$cookies.setCookie("formulo_jwt_token", jwt_token)
						this.removeAuthCookies()
					}).catch(
					err=>
					{
						err.message = "Google authentication failed".
							this.removeAuthCookies()
					})
			}
		}
		
   
	},
	methods:{
		verifyState(state){
			return this.$cookies.getCookie('state') === state
		},

		async initiateGoogleAuthentication(type, authCode, authService){
			if(type === AuthTypeEnum.SIGN_IN){
				return authService.signInWithGoogle(authCode).then(response => {
					return response.data.data.jwt_token
				}).catch(err => {
					err.message =  "Sign in with google failed"
				});	
			}
			else{
				return authService.signUpWithGoogle(authCode).then(response=>{
					return response.data.data.jwt_token
				}).catch(err => {
					err.message =  "Sign up with google failed"
				});	
			}

		},
		async initiateFacebookAuthentication(type, authCode, authService){
			if(type === AuthTypeEnum.SIGN_IN){
				return authService.signInWithFacebook(authCode).then((response)=>{
					return response.data.data.jwt_token
				}).catch(err=>{
					err.message =  "Sign in with facebook failed"
				})	
			}
			else{
				return authService.signUpWithFacebook(authCode).then((response)=>{
					return response.data.data.jwt_token
				}).catch(err=>{
					err.message =  "Sign up with facebook failed"
				})	
							
			}
		},
		removeAuthCookies(){
			this.$cookies.removeCookie('auth_name')
			this.$cookies.removeCookie('auth_type')
			this.$cookies.removeCookie('state')
		}
	},
	
	
}
</script>

<style></style>