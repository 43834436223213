import axios from "axios";

export class AuthService{
	constructor(){
		this.googleAuthServiceUrl = '/user/google-auth'
		this.facebookAuthServiceUrl = '/user/facebook-auth'
	}
	
	async signInWithGoogle(authCode) {
		return axios.get(`${this.googleAuthServiceUrl}/signin-with-google`,
			{
				headers: { 'Authorization': `Bearer ${authCode}` }
			}
		)
	}

	async signUpWithGoogle(authCode) {
		return axios.get(`${this.googleAuthServiceUrl}/signup-with-google`,
			{
				headers: { 'Authorization': `Bearer ${authCode}` }
			}
		)
	}

	async signInWithFacebook(authCode) {
		return axios.get(`${this.facebookAuthServiceUrl}/signin-with-facebook`,
			{
				headers: { 'Authorization': `Bearer ${authCode}` }
			}
		)
	}

	async signUpWithFacebook(authCode) {
		return axios.get(`${this.facebookAuthServiceUrl}/signup-with-facebook`,
			{
				headers: { 'Authorization': `Bearer ${authCode}` }
			}
		)
	}
}

