<template>
	<div class="container-md">
		<div class="row row-cols-1 row-cols-sm-2 row-cols-lg-3 g-4 my-5 card-group">
			<div
				v-for="product in filteredProducts"
				:key="product.product_id"
				class="col rounded-5"
			>
				<ProductCard 
					:product="product"
				/>
			</div>
		</div>
	</div>
</template>
<script>
import ProductCard from '@/Products/components/ProductCard.vue';
import {ref,onMounted} from 'vue'
import AllProductsService from '../services/AllProductsService';
export default {
	name:"AllProductsPage",
	components: {
		ProductCard
	},
	setup(){
		let searchProducts=ref('')
		const products =ref([])
		const filteredProducts =ref([])
		const fetchProducts = async ()=> {
			await AllProductsService.getAllProducts().then((res) =>{
				products.value=res.data;
				filteredProducts.value = res.data;
			})
				.catch(err => {
					err.message = `Request failed please try again later`
				})
		};

		function searchAndGetProduct(){
			const query = searchProducts.value.toLowerCase();
			filteredProducts.value = products.value.filter(product =>
				product.product_name.toLowerCase().includes(query)
			);
			
		}
		onMounted(() => {
			fetchProducts();
		});
		return{
			searchProducts,
			filteredProducts,
			searchAndGetProduct
		}
	}
}
</script>
<style>
.outline-input:focus {
 outline: none;
}
</style>