<template>
	<div v-if="verifyOtp && !showSignIn">
		<font-awesome-icon 
			:icon="['fas', 'arrow-left']" 
			role="button"
			class="py-2"
			@click="backToSignIn"
		/>
		<div class="text-center">
			<div class="mb-4">
				<p class="text-muted mb-0">
					Enter the OTP sent to :
				</p>
				<div class="d-flex align-items-center justify-content-center py-2">
					<span
						class="text-decoration-underline px-1"
					> 
						+91 
						{{ state.form.contactNumber }}
					</span>
					<div class="text-start">
						<font-awesome-icon 
							:icon="['fas', 'pen']" 
							role="button"
							@click="backToSignIn"
						/>
					</div>
				</div>
			</div>
			<div 
				ref="otpContainer"
				class="my-3"
			>
				<input
					v-model="otpInput"
					type="text"
					class="otpBox col-10 text-center"
					:autofocus="true"
					autocomplete="one-time-code"
					maxlength="4"
					@keypress="validateInput"
				>
			</div>
			<p class="pt-4">
				Didn't receive OTP? <span 
					role="button"
					:class="resendOtpClass" 
					@click="resendOTP"
				>{{ resendOtpText }} 
					<span 
						v-if="counter>0" 
						class="text-info"
					>{{ counter }}</span></span>
			</p>
			<button 
				type="button" 
				class="btn btn-lg btn-warning my-3" 
				@click="submitForm"
			>
				{{ tabsData.tab }}
			</button>
		</div>
	</div>
	<div v-else>
		<div class="form-group my-3">
			<label class="h6 pb-1">Contact Number</label>
			<div 
				class="form-control box border-black d-flex align-items-center"
			>
				<img 
					class="flag img-fluid" 
					alt="flag" 
					src="/images/india-flag-icon.png"
				>
				<span class="mx-2">+91</span>
				<input 
					ref="contactNumber"
					v-model="state.form.contactNumber"
					type="number"
					class="form-control phoneInput border-0 shadow-none px-1"
				>
			</div>	
			<div 
				v-if="v$.form.contactNumber.$invalid && v$.form.contactNumber.$dirty" 
				class="text-danger"
			>
				{{ v$.form.contactNumber.$errors[0].$message }}
			</div>
		</div>	
		<div class="text-end">
			<button 
				type="button" 
				class="btn btn-warning btn-lg rounded-4 p-2 col-4 h6"
				@click="otpVerification"
			>
				Verify
			</button>
		</div>
		<div class="d-flex justify-content-center pt-4">
			<p class="text-center">
				{{ tabsData.text }}
				<span 
					class="bold"
					style="cursor: pointer;" 
					@click="goToSignUp"
				>
					{{ tabsData.tabSwitch }}
				</span>
			</p>
		</div>
	</div>
</template>
<script>
import { ref, computed } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, minLength, maxLength } from '@vuelidate/validators'

export default {
	name: "OtpInput",
	props: {
		tabsData: {
			type: Object,
			required: true
		}
	},
	emits: ['go-to-quiz', 'go-to-tab'],
	setup() {
		const otpInput = ref('');
		const otpContainer = ref();
		
		const state = ref({
			form: {
				contactNumber: '',
			}
		})

		const rules = computed(function() {
			return {
				form: {
					contactNumber: { required, minLength: minLength(10),maxlength: maxLength(10) },
				}
			}
		})

		const v$ = useVuelidate(rules, state)

		return {
			otpInput,
			otpContainer,
			state,
			v$
		};
	},
	data() {
		return {
			verifyOtp: false,
			showSignIn: false,
			showQuiz: false,
			counter: 45,
			counterInterval: null
		}
	},
	computed: {
		resendOtpText() {
			return this.counter > 0 ? 'Resend OTP in' : 'Resend OTP';
		},
		resendOtpClass() {
			return this.counter > 0 ? '' : 'text-info cursor-pointer';
		}
	},
	methods: {
		validateInput(event) {
			const key = event.key;
			const pattern = /^[0-9]$/;
			if (!pattern.test(key)) {
				event.preventDefault();
			}else if (this.otpInput.length === 4 && this.showQuiz) {
				this.submitForm(); 
			}
		},
		resendOTP() {
			if (this.counter === 0) {
				this.counter = 45;
				this.startCounter();
			}
		},
		startCounter() {
			if (this.counterInterval) {
				clearTimeout(this.counterInterval);
			}

			if (this.counter > 0) {
				this.counterInterval = setTimeout(() => {
					this.counter--;
					this.startCounter();
				}, 1000);
			}
		},
		otpVerification() {
			this.v$.$validate().then((isValid) => {
				if (isValid) {
					this.verifyOtp = true;
					this.showSignIn = false;
					this.counter = 45;
					this.otpInput = '';
					this.startCounter();
					this.$nextTick(() => {
						this.$refs.otpContainer.querySelector('input').focus();
					});
				}
			});
			
		},
		backToSignIn() {
			this.showSignIn = true;
			this.$nextTick(() => {
				this.$refs.contactNumber.focus();
			});
		},
		submitForm() {
			this.showQuiz=true;
			this.$emit('go-to-quiz');
		},
		goToSignUp() {
			this.$emit('go-to-tab', this.tabsData.tabSwitch);
			this.$router.push({ query: { tab: this.tabsData.tabSwitch } });
		}
	}
}
</script>

<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.otpBox{
	border: none;
	border-bottom: 3px solid #d3d3d3;
}
.otpBox:focus{
	outline: none;
	border-bottom: 3px solid rgba(0, 0, 0, 0.5);
}
</style>