<template>
	<div id="pricing-section">
		<div 
			class="container-lg py-3"
		>
			<div class="row text-center my-5 pb-sm-5">
				<h2>Choose a plan that works for you</h2>
			</div>
			<div 
				id="pricing-card-group" 
				class="card-group my-5"
			>
				<div
					class="card price-card bg-white rounded-5 mx-sm-3"
					@mouseenter="scaleUp"
					@mouseleave="scaleDown"
				>
					<div class="card-body mt-4 mx-sm-3">
						<h3 class="card-title mb-5">
							3 Months Treatment
						</h3>
						<div class="card-text">
							<div class="row">
								<div class="col-2 text-end">
									<font-awesome-icon
										:icon="['fas', 'circle-check']"
										size="xl"
										class="pricing-check-icon"
									/>
								</div>
								<div class="col text-start">
									<h5>
										One Time Payment
									</h5>
								</div>	
							</div>
							<div class="row">
								<div class="col-2 text-end">
									<font-awesome-icon
										:icon="['fas', 'circle-check']"
										size="xl"
										class="pricing-check-icon"
									/>
								</div>
								<div class="col text-start">
									<h5>
										Complete Treatment With Best Results
									</h5>
								</div>	
							</div>
							<div class="row">
								<div class="col-2 text-end">
									<font-awesome-icon
										:icon="['fas', 'circle-check']"
										size="xl"
										class="pricing-check-icon"
									/>
								</div>
								<div class="col text-start">
									<h5>
										Experience more savings
									</h5>
								</div>	
							</div>
						</div>
					</div>
					<div class="card-footer mx-sm-3 border-0 bg-white rounded-5">
						<h2 
							class="py-3"
						>
							₹2,499
						</h2>
						<div class="text-end">
							<router-link :to="{ path: '/authentication', query: { tab: 'Sign Up' } }">
								<button 
									class="btn btn-warning h5 px-3 py-2 rounded-4"
								>
									Select Plan
								</button>
							</router-link>
						</div>
					</div>
					<span class="badge h6 rounded-pill bold bg-warning text-dark p-3">Most Popular</span>
				</div>
				<div
					class="card bg-white price-card rounded-5 mx-sm-3"
					@mouseenter="scaleUp"
					@mouseleave="scaleDown"
				>
					<div class="card-body mt-4 mx-sm-3">
						<h3 
							class="card-title mb-5"
						>
							Monthly Treatment
						</h3>
						<div class="card-text">
							<div class="row">
								<div class="col-2 text-end">
									<font-awesome-icon
										:icon="['fas', 'circle-check']"
										size="xl"
										class="pricing-check-icon"
									/>
								</div>
								<div class="col text-start">
									<h5>
										Cancel Anytime
									</h5>
								</div>	
							</div>
							<div class="row">
								<div class="col-2 text-end">
									<font-awesome-icon
										:icon="['fas', 'circle-check']"
										size="xl"
										class="pricing-check-icon"
									/>
								</div>
								<div class="col text-start">
									<h5>
										3 months treatment for best results
									</h5>
								</div>
							</div>
						</div>
					</div>
					<div class="card-footer mx-sm-3 border-0 bg-white rounded-5">
						<h2 
							class="py-3"
						>
							₹1,199
						</h2>
						<div class="text-end">
							<router-link :to="{ path: '/authentication', query: { tab: 'Sign Up' } }">
								<button 
									class="btn btn-warning h5 px-3 py-2 rounded-4"
								>
									Select Plan
								</button>
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import gsap from '@/config/gsapEffects'
import FormuloWebConstants from '@/config/formuloWebConstants'
export default {
	components: {},
	data(){
		return {
			primaryYellowStyle:`color :${FormuloWebConstants.primaryYellowColorHex}`
		}
	},
	mounted(){

		gsap.fromTo("#pricing-section", 
			{backgroundColor: "white"},
			{
				backgroundColor: FormuloWebConstants.blueColorHex,
				duration: 0.5,
				delay: 0.5,
				scrollTrigger: {
					trigger: "#pricing-section",
					start: "top 70%",
					toggleActions: "restart none none reverse",
				}
			}
		)

		gsap.effects.fadeIn(".price-card", {duration: 1.2, scrollTrigger: {
			trigger: "#pricing-section",
			start: "top center",
		},stagger: 0.3})

		let lgMinBreakpoint = FormuloWebConstants.customLgMinWidthBreakpoint
		let mm = gsap.matchMedia()

		mm.add(`(min-width: ${lgMinBreakpoint})`, () => {
		

			gsap.to("#pricing-section", 
				{
					margin: "3%",
					borderRadius: "5vh",
					scrollTrigger: {
						trigger: "#pricing-section",
						start: "bottom 80%",
						scrub: true,
					}
				})
		});
	},
	methods: {
		scaleUp(ele) {
			gsap.effects.cardScaleUp(ele.target)
		},
		scaleDown(ele) {
			gsap.effects.cardScaleDown(ele.target)
		}
	},
}
</script>
<style scoped>
#pricing-section{
	height: 100%;
	background-color: var(--blue-color-variant);
}
#pricing-card-group{
	height: 50%;
}
.price-card{
	cursor: pointer;
}

.badge {
	position: absolute;
	left: 35%;
	bottom: 100%;
	transform: translate(-50%, 50%);
}

.pricing-check-icon{
	color: var(--yellow-primary-color-variant)
}
</style>