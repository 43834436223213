<template>
	<div class="clause__section container p-4">
		<div v-if="policyData">
			<div class="py-5 clause__header bg-white">
				<h2>{{ policyData.title }}</h2>
				<span class="fs-5 text-secondary">
					Last updated on {{ policyData.lastUpdated }}
				</span>
			</div>
			<div class="clause__body">
				<ClauseInfo
					v-for="({ clauseTitle, clauseContent }, i) in policyData.clauseData"
					:key="i"
					class="mb-4"
					:clause-title="clauseTitle"
				>
					<template #clause-content>
						{{ clauseContent }}
					</template>
				</ClauseInfo>
			</div>
		</div>
		<ErrorAlert
			v-else-if="errorMessage"
			:message="errorMessage"
		/>
	</div>
</template>
<script setup>
import ClauseInfo from '@/TermsOfUse/components/ClauseInfo.vue';
import ErrorAlert from '@/common/components/ErrorAlert.vue';
import { ref } from 'vue'

const props = defineProps(['source']);
const policyData = ref(null);
const errorMessage = ref(null);

fetch(props.source)
	.then(response => response.json())
	.then(data => {
		policyData.value = data;
	})
	.catch(() => {
		errorMessage.value = "Unable to load content for this page";
	})
</script>
