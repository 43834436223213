<template>
	<Teleport to="body">
		<div
			id="step-modal"
			class="modal fade"
			tabindex="-1"
		>
			<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable mw-100">
				<div
					class="modal-content mx-auto"
					style="max-height: 90vh; max-width: 90vw;"
				>
					<div class="modal-header justify-content-between border-0 align-items-start">
						<div class="px-2 px-md-5 pt-4">
							<h6>{{ modalData.header }}</h6>
							<h3 class="modal-title">
								{{ modalData.title }}
							</h3>
						</div>
						<font-awesome-icon 
							:icon="['fas', 'fa-circle-xmark']"
							size="2xl"
							style="cursor: pointer;"
							data-bs-dismiss="modal"
							aria-label="Close"
						/>
					</div>
					<div class="modal-body">
						<div 
							v-for="(point, i) in modalData.points" 
							:key="i" 
							class="p-4 p-md-5 mx-2 mx-md-5 mb-4 mb-md-5 bg-light rounded-5"
						>
							<h5 class="mb-4">
								{{ point.label }}:
								<span class="text-secondary">{{ point.content }}</span>
							</h5>
							<picture>
								<source
									media="(min-width: 768px)"
									:srcset="`images/${point.image}-2.png`"
								>
								<img 
									class="w-100 object-fit-cover" 
									:src="`images/${point.image}-1.png`"
									loading="lazy"
								>
							</picture>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Teleport>
</template>
<script>
export default {
	props: ['modalData']
}
</script>
<style scoped>
img {
	aspect-ratio: 1 / 1;
	max-height: 60vh;
}
@media (min-width: 768px) {
	.modal-content {
		width: 75%;
	}
}
@supports (scrollbar-width: none) {
	.modal-body {
		scrollbar-width: none;
	}
}
@supports selector(::-webkit-scrollbar) {
	.modal-body::-webkit-scrollbar {
		display: none;
	}
}
</style>