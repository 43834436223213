<template>
	<div
		id="treatment-works-section"
		class="py-5"
	>
		<div class="container-lg">
			<h3 class="text-start pb-5 px-4">
				Treatment that works for you
			</h3>
			<CarouselV1
				:card-names="cardNames" 
				:show-direction-controls="false"
				class="row"
			>
				<template #personalizationCard>
					<div class="col ms-2 h-100 p-2">
						<div
							class="card h-100 border-0 rounded-5 overflow-hidden"
							@mouseenter="scaleUp" 
							@mouseleave="scaleDown"
						>
							<div
								class="card-body p-0 d-flex flex-column justify-content-between gap-4"
							>
								<div class="p-3 mb-4">
									<div class="row align-items-center mb-3 g-2 p-2">
										<div class="col-12 col-lg-3">
											<img
												class="img-fluid"
												src="/images/personalization.png"
												alt="personalization"
											>
										</div>
										<div class="col-12 col-lg-9">
											<h5 class="card-title mb-0">
												Personalization
											</h5>
										</div>
									</div>
									<p class="card-text">
										Formulo personalized skincare regimens are tailored for your skin.
									</p>
								</div>
								<picture>
									<source
										media="(min-width:768px)"
										srcset="images/personalization-bg-2.png"
									>
									<img
										src="images/personalization-bg-1.png"
										class="w-100 object-fit-cover card__img"
										loading="lazy"
									>
								</picture>
							</div>
						</div>
					</div>
				</template>
				<template #clinicallyTestedCard>
					<div class="col ms-2 h-100 p-2">
						<div
							class="card h-100 border-0 rounded-5 overflow-hidden"
							@mouseenter="scaleUp" 
							@mouseleave="scaleDown"
						>
							<div class="card-body p-0 d-flex flex-column justify-content-between gap-4">
								<div class="p-3 mb-4">
									<div class="row align-items-center mb-3 g-2 p-2">
										<div class="col-12 col-lg-3">
											<img
												class="img-fluid"
												src="/images/quality.png"
												alt="quality"
											>
										</div>
										<div class="col-12 col-lg-9">
											<h5 class="card-title mb-0">
												Clinically Tested
											</h5>
										</div>
									</div>
									<p class="card-text">
										We source only doctor-approved, certified ingredients to formulate effective products.
									</p>
								</div>
								<picture>
									<source
										media="(min-width:768px)"
										srcset="images/tested-bg-2.png"
									>
									<img
										src="images/tested-bg-1.png"
										class="w-100 object-fit-cover card__img"
										loading="lazy"
									>
								</picture>
							</div>
						</div>
					</div>
				</template>
				<template #convinenceCard>
					<div class="col ms-2 h-100 p-2">
						<div
							class="card h-100 border-0 rounded-5 overflow-hidden"
							@mouseenter="scaleUp" 
							@mouseleave="scaleDown"
						>
							<div class="card-body p-0 d-flex flex-column justify-content-between gap-4">
								<div class="p-3 mb-4">
									<div class="row align-items-center mb-3 g-2 p-2">
										<div class="col-12 col-lg-3">
											<img
												class="img-fluid"
												src="/images/convenience.png"
												alt="convenience"
											>
										</div>
										<div class="col-12 col-lg-9">
											<h5 class="card-title mb-0">
												Control
											</h5>
										</div>
									</div>	
									<p class="card-text">
										Connect with your doctor anytime, without queues or long wait times.
									</p>
								</div>
								<picture>
									<source
										media="(min-width:768px)"
										srcset="images/control-bg-2.png"
									>
									<img
										src="images/control-bg-1.png"
										class="w-100 object-fit-cover card__img"
										loading="lazy"
									>
								</picture>
							</div>
						</div>
					</div>	
				</template>
			</CarouselV1>	
		</div>
	</div>
</template>
<script>
import gsap from '@/config/gsapEffects'
import CarouselV1 from '@/common/components/carousel/Carousel.vue'
export default {
	name: "TreatmentWorksSection",
	components: {
		CarouselV1
	},
	data(){
		return {
			cardNames:['personalizationCard','clinicallyTestedCard','convinenceCard'],
		}
	},
	mounted: () => {
		gsap.effects.fadeIn("#treatment-works-section .card", {duration: 1, stagger: 0.4, scrollTrigger: {
			trigger: "#treatment-works-section",
			start: "top 40%",
		}})
	},
	methods: {
		scaleUp(ele) {
			gsap.effects.cardScaleUp(ele.target)
		},
		scaleDown(ele) {
			gsap.effects.cardScaleDown(ele.target)
		}
	}
}
</script>

<style>
#treatment-works-section .card {
	background-image: linear-gradient(to bottom, #fff1d6, #fff);
}
#treatment-works-section .card__img {
	aspect-ratio: 1.25 / 1;
	object-position: center top;
}
</style>