<template>
	<div 
		id="treatment" 
		class="py-5"
	>
		<div class="bg-color">
			<div class="row px-2 py-5">
				<h2 class="text-center">
					Personalized Treatement
				</h2>
			</div>
			<div class="row px-4 justify-content-center gap-5 pb-5 align-items-center">
				<div class="col-12 col-lg-5 col-sm-5 ">
					<p class="mb-0">
						Our personalized skincare service is crafted by highly practiced and experienced dermatologists to meet your unique needs. Using only the finest, clinically tested products and prescription medications, we ensure that you receive the best possible care. Plus, with priority delivery, your custom-tailored skincare essentials will be brought straight to your doorstep.
					</p>
				</div>
				<div class="col-12 col-sm-4 text-center">
					<img 
						src="/images/treatment.png" 
						alt="treatment" 
						class="img-fluid object-fit-cover"
					>
				</div>
			</div>
		</div>
		<IconSection name="hs-bottom" />
	</div>
</template>
<script>
import IconSection from '@/common/svgs/icons.vue'
export default {
	name: "PersonalizedTreatmentSection",
	components: {
		IconSection
	}
}
</script>
<style>
.bg-color{
	background-color: var(--yellow-secondary-color-variant)
}
</style>