<template>
	<div class="card h-100">
		<div
			class="card-img-top"
			@click="showProduct"
		>
			<picture class="img-height">
				<source
					media="(min-width:1025px)"
					:src="product.product_images_3[0]" 
				>
				<img 
					class="img-fluid card-img-top h-100 object-fit-cover" 
					:alt="product.product_name"
					:src="product.product_images_1[0]" 
					loading="lazy"
				>
			</picture>
		</div>
		<div
			class="card-body"
		>
			<div class="col-9">
				<h5
					class="card-title title-height"
					@click="showProduct"
				>
					{{ product.product_name }} 
				</h5>
				<h6 class="card-subtitle subtitle-height pt-1 pb-3">
					Rs. {{ product.product_discounted_price }}
				</h6>
				<p class="card-text text-height">
					{{ product.product_actives }}
				</p>
			</div>
		</div>
		<div class="card-footer bg-transparent border-0">
			<div class="col-12 d-flex justify-content-end">
				<button
					class="btn btn-warning d-flex  align-items-center rounded-4"
					@click="addToCart"
				>
					<IconSection name="s-bag" /> 
					<span class="px-1 fw-bold">Add to Cart</span>
				</button>
			</div>
		</div>
	</div>
</template>
<script>
import IconSection from '@/common/svgs/icons.vue'
export default {
	name:'ProductsCard',
	components:{
		IconSection
	},
	props:{
		product:{
			type:Object,
			required:true,
		}
	},
	methods:{
		showProduct(){
			this.$router.push({ path: `/products/${this.product.product_id}`})
		},
		addToCart(){
			console.log("Add to cart clicked called")

		}
	}
}
</script>
<style>

.card-img-top, .card-title{
	cursor: pointer;
}


</style>