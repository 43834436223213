<template>
	<div id="steps-section">
		<div 
			class="container-lg p-3 py-sm-5"
		>
			<div class="row py-sm-5 py-2">
				<div class="text-start">
					<h2>Getting Started is Easy</h2>
				</div>
			</div>
			<div class="row row-cols-1 row-cols-md-3 g-4">
				<div
					class="col step-information"
					@mouseenter="scaleUp"
					@mouseleave="scaleDown"
					@click="() => showModal('online-consultation')"
				>
					<div class="card h-100 rounded-5 justify-content-between overflow-hidden">
						<div class="card-body">
							<div class="mb-3 card__snippet">
								<h5 class="pb-2">
									Step 1
								</h5>
								<h3 class="card-title">
									Online Consultation
								</h3>
							</div>
							<p class="py-4 card-text steps-head">
								Talk to our expert and discuss your skin goals
							</p>
						</div>
						<div class="card__img d-flex justify-content-end">
							<picture>
								<source
									media="(min-width:768px)"
									srcset="images/doctor-2.png"
								>
								<img 
									class="img-fluid h-100 object-fit-cover" 
									alt="formulo" 
									src="/images/doctor-1.png"
									loading="lazy"
								>
							</picture>
						</div>
					</div>
				</div>
				<div
					class="col step-information"
					@mouseenter="scaleUp"
					@mouseleave="scaleDown"
					@click="() => showModal('personal-skincare')"
				>
					<div class="card h-100 rounded-5 justify-content-between overflow-hidden">
						<div class="card-body">
							<div class="mb-3 card__snippet">
								<h5 class="pb-2">
									Step 2
								</h5>
								<h3 class="card-title">
									Skincare Routine Delivered
								</h3>
							</div>
							<p class="py-4 card-text steps-head">
								We deliver your personalized prescription medication
							</p>
						</div>
						<div class="card__img d-flex justify-content-end">
							<picture>
								<source
									media="(min-width:768px)"
									srcset="images/delivery-2.png"
								>
								<img 
									class="img-fluid h-100 object-fit-cover" 
									alt="formulo" 
									src="/images/delivery-1.png"
									loading="lazy"
								>
							</picture>
						</div>
					</div>
				</div>
				<div
					class="col step-information"
					@mouseenter="scaleUp"
					@mouseleave="scaleDown"
					@click="() => showModal('follow-up')"
				>
					<div class="card h-100 rounded-5 justify-content-between overflow-hidden">
						<div class="card-body">
							<div class="mb-3 card__snippet">
								<h5 class="pb-2">
									Step 3
								</h5>
								<h3 class="card-title">
									Follow-up Appointments
								</h3>
							</div>
							<p class="py-4 card-text steps-head">
								Schedule follow up appointments to check in your treatment
							</p>
						</div>
						<div class="card__img d-flex justify-content-end">
							<picture>
								<source
									media="(min-width:768px)"
									srcset="images/followup-2.png"
								>
								<img 
									class="img-fluid h-100 object-fit-cover" 
									alt="formulo" 
									src="images/followup-1.png"
									loading="lazy"
								>
							</picture>
						</div>
					</div>
				</div>
				<StepSectionModal 
					v-if="modalData && modalID !== null"
					:modal-data="modalData.find(data => data.modalID === modalID)"
				/>
				<ErrorModal
					v-else-if="!modalData"
					modal-id="step-error-modal"
					message="Unable to load content for this modal"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import gsap from '@/config/gsapEffects'
import FormuloWebConstants from '@/config/formuloWebConstants'
import StepSectionModal from '@/HomePage/components/StepSectionModal.vue'
import ErrorModal from '@/common/components/ErrorModal.vue'
import { Modal } from 'bootstrap'
export default {
	name: "StepsSection",
	components: { 
		StepSectionModal,
		ErrorModal
	},
	data() {
		return { 
			modalData: null,
			modalID: null
		}
	},
	created() {
		fetch("json/steps-modal-data.json")
			.then(response => response.json())
			.then(data => {
				this.modalData = data
			})
	},
	mounted() {
		gsap.to(".steps-head", {borderTop: `0.5vh solid ${FormuloWebConstants.primaryYellowColorHex}`, duration: 1})

		gsap.fromTo("#steps-section", 
			{backgroundColor: "white"},
			{
				backgroundColor: FormuloWebConstants.blueColorHex,
				duration: 0.5,
				delay: 0.5,
				scrollTrigger: {
					trigger: "#steps-section",
					start: "top 70%",
					end: "+10%",
					toggleActions: "restart none none reverse",
					scrub: true
				}
			}
		)

		gsap.effects.fadeIn(".step-information", {duration: 1, stagger: 0.2, scrollTrigger: {
			trigger: ".step-information",
			start: "top center",
		}})

		let mm = gsap.matchMedia()
		let lgMinBreakpoint = FormuloWebConstants.customLgMinWidthBreakpoint
		
		mm.add(`(min-width: ${lgMinBreakpoint})`, () => {
		

			gsap.to("#steps-section", 
				{
					margin: "3%",
					borderRadius: "5vh",
					scrollTrigger: {
						trigger: "#steps-section",
						start: "bottom 80%",
						scrub: true,
					}
				})
		});
	},
	methods: {
		scaleUp(ele) {
			gsap.effects.cardScaleUp(ele.target)
		},
		scaleDown(ele) {
			gsap.effects.cardScaleDown(ele.target)
		},
		showModal(modalID) {
			if (!this.modalData) {
				const errorModalBs = new Modal(document.getElementById('step-error-modal'))
				errorModalBs.show();
				return
			}
			this.modalID = modalID
			this.$nextTick(() => {
				const stepModal = document.getElementById('step-modal')
				if (stepModal) {
					const onHide = () => {
						stepModal.removeEventListener('hidden.bs.modal', onHide)
						this.modalID = null
					}
					stepModal.addEventListener('hidden.bs.modal', onHide)
					const stepModalBs = new Modal(stepModal)
					stepModalBs.show()
				}
			})
		}
	}
}
</script>

<style>
#steps-section {
	min-height: 90vh;
}

#steps-section .card__img {
	max-height: 35vh;
	aspect-ratio: 1 / 1.3; /* OPTIONAL FOR IPAD MINI AND AIR */
	img {
		object-position: center top;
	}
}

#steps-section .step-information {
	cursor: pointer;
}

#steps-section .card__snippet {
	height: 60%;
}
</style>