import { createRouter,createWebHistory } from 'vue-router'
import NavBar from '@/common/components/NavBar.vue'
import FooterPage from '@/common/components/Footer.vue'
import HomePage from '@/HomePage/view/HomePageView.vue'
import ClauseSection from '@/TermsOfUse/view/ClauseSection.vue'
import AuthenticationPage from '@/Authentication/components/AuthenticationPage.vue'
import WhyFormuloView from '@/WhyFormulo/view/WhyFormuloView.vue'
import ProductsView from '@/Products/view/ProductsView.vue'
import AllProductsPage from '@/Products/components/AllProductsPage.vue';
import SingleProductPage from '@/Products/components/SingleProductPage.vue'
import CartPage from '@/CartPage/view/CartPage.vue'

const routes = [
	{
		path: '/',
		name: 'Home',
		components: {
			default: HomePage,  
			navbar: NavBar,
			footer: FooterPage
		}
	},
	{
		path: '/cart',
		name: 'Cart',
		components: {
			default: CartPage, 
			navbar: NavBar,
			footer: FooterPage
		}
	},
	{
		path: '/terms-of-use',
		name: 'Terms of Use',
		components: {
			default: ClauseSection, 
			navbar: NavBar,
			footer: FooterPage
		},
		props: {
			default: { 
				source: 'json/termsofuse.json' 
			}
		}
	},
	{
		path: '/refund-exchange-policy',
		name: 'Refund and Exchange Policy',
		components: {
			default: ClauseSection, 
			navbar: NavBar,
			footer: FooterPage
		},
		props: {
			default: { 
				source: 'json/refundexchange.json' 
			}
		}
	},
	{
		path: '/authentication',
		name: 'Authentication',
		components: {
			default: AuthenticationPage,  
			navbar: NavBar,
			footer: FooterPage
		} ,
		meta: { background: true },
  
	},
	{
		path: '/whyformulo',
		name: 'WhyFormulo',
		components: {
			default: WhyFormuloView,  
			navbar: NavBar,
			footer: FooterPage
		} ,

	},
	{
		path: '/products',
		name: 'Products',
		components: {
			default: ProductsView,  
			navbar: NavBar,
			footer: FooterPage
		} ,
		children:[
			{
				path:'',
				name: 'AllProductsPage',
				component: AllProductsPage
			},
			{
				path:':productId',
				name: 'SingleProductPage',
				component: SingleProductPage,
				props: route => ({ productId: route.params.productId })
			}
		]
	}

]
  
const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});
  
export default router