<template>
	<div class="clause">
		<h5>{{ clauseTitle }}</h5>
		<p>
			<slot name="clause-content" />
		</p>
	</div>
</template>
<script>
export default {
	props: ['clauseTitle']
}
</script>