<template>
	<div class="row d-flex justify-content-center">
		<div class="col-10 col-sm-8 col-xl-8 my-2 text-center">
			<div
				class="col-12 border border-2 border-dark text-black btn btn-lg rounded-4"
				@click="onInitiateFacebookAuthHandler"
			> 
				<div  
					class="row align-items-center" 
				>
					<div class="col-2">
						<IconSection 
							:name="buttons.name"
						/>
					</div>
					<div class="col text-start">
						<span class="">
							{{ buttons.text }}
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import IconSection from '@/common/svgs/icons.vue'
import FacebookAuthConfigs from '@/config/facebookAuthConfigs';
export default {
	name:'FacebookAuthenticationButton',
	components:{
		IconSection
	},
	props:{
		buttons:{
			type: Object,
			required: true
		}
	},
	methods:{
		onInitiateFacebookAuthHandler(){
			const state = (Math.random()+1).toString(36).substring(2)

			this.$cookies.setCookie('state', state)
			this.$cookies.setCookie('auth_type', this.buttons.type)
			this.$cookies.setCookie('auth_name', this.buttons.name)

			const facebookAuthConfigs = new FacebookAuthConfigs(this.$config)
			facebookAuthConfigs.getFacebookAuthCode(state)
		}
	}
}
</script>