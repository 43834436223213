<template>
	<AllProductsPage v-if="!$route.params.productId" />
	<SingleProductPage 
		v-else 
		:product-id="$route.params.productId"
	/>
</template>
<script>
import AllProductsPage from '@/Products/components/AllProductsPage.vue';
import SingleProductPage from '../components/SingleProductPage.vue';
export default {
	name: "ProductsView",
	components: {
		AllProductsPage,
		SingleProductPage
	}
}
</script>
<style>
</style> 