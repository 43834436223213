<template>
	<svg
		v-if="name === 'user'"
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="25"
		viewBox="0 0 24 25"
		fill="none"
	>
		<path
			d="M20 21.5V19.5C20 18.4391 19.5786 17.4217 18.8284 16.6716C18.0783 15.9214 17.0609 15.5 16 15.5H8C6.93913 15.5 5.92172 15.9214 5.17157 16.6716C4.42143 17.4217 4 18.4391 4 19.5V21.5"
			stroke="black"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M12 11.5C14.2091 11.5 16 9.70914 16 7.5C16 5.29086 14.2091 3.5 12 3.5C9.79086 3.5 8 5.29086 8 7.5C8 9.70914 9.79086 11.5 12 11.5Z"
			stroke="black"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
	<svg
		v-else-if="name === 's-bag'"
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="25"
		viewBox="0 0 24 25"
		fill="none"
	>
		<path
			d="M6 2.5L3 6.5V20.5C3 21.0304 3.21071 21.5391 3.58579 21.9142C3.96086 22.2893 4.46957 22.5 5 22.5H19C19.5304 22.5 20.0391 22.2893 20.4142 21.9142C20.7893 21.5391 21 21.0304 21 20.5V6.5L18 2.5H6Z"
			stroke="black"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M3 6.5H21"
			stroke="black"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M16 10.5C16 11.5609 15.5786 12.5783 14.8284 13.3284C14.0783 14.0786 13.0609 14.5 12 14.5C10.9391 14.5 9.92172 14.0786 9.17157 13.3284C8.42143 12.5783 8 11.5609 8 10.5"
			stroke="black"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
	<svg
		v-else-if="name === 'menu'"
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="25"
		viewBox="0 0 24 25"
		fill="none"
	>
		<path
			d="M3 12.5H21"
			stroke="black"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M3 6.5H21"
			stroke="black"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M3 18.5H21"
			stroke="black"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
	<svg
		v-else-if="name === 'down'"
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
	>
		<path
			d="M6 9L12 15L18 9"
			stroke="black"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
	<svg
		v-else-if="name === 'facebook'"
		xmlns="http://www.w3.org/2000/svg"
		width="52"
		height="51"
		viewBox="0 0 52 51"
		fill="none"
	>
		<path
			d="M47.1154 25.5C47.1154 13.77 37.5523 4.25 25.7693 4.25C13.9862 4.25 4.4231 13.77 4.4231 25.5C4.4231 35.785 11.7662 44.3487 21.5 46.325V31.875H17.2308V25.5H21.5V20.1875C21.5 16.0863 24.8514 12.75 28.9712 12.75H34.3077V19.125H30.0385C28.8644 19.125 27.9039 20.0813 27.9039 21.25V25.5H34.3077V31.875H27.9039V46.6438C38.6837 45.5813 47.1154 36.5288 47.1154 25.5Z"
			fill="#0B1215"
		/>
	</svg>
	<svg
		v-else-if="name === 'instagram'"
		xmlns="http://www.w3.org/2000/svg"
		width="52"
		height="51"
		viewBox="0 0 52 51"
		fill="none"
	>
		<path
			d="M32.4037 25.5C32.4037 26.7609 32.0281 27.9934 31.3244 29.0418C30.6208 30.0901 29.6206 30.9072 28.4505 31.3897C27.2803 31.8722 25.9927 31.9985 24.7505 31.7525C23.5083 31.5065 22.3672 30.8994 21.4716 30.0078C20.576 29.1162 19.9661 27.9803 19.719 26.7437C19.4719 25.5071 19.5987 24.2253 20.0834 23.0604C20.5681 21.8955 21.3889 20.8999 22.442 20.1994C23.4951 19.4989 24.7332 19.125 25.9998 19.125C27.6966 19.1303 29.3224 19.8036 30.5222 20.998C31.722 22.1924 32.3984 23.8109 32.4037 25.5ZM46.0118 16.7344V34.2656C46.0118 37.2244 44.8311 40.0621 42.7295 42.1543C40.6278 44.2465 37.7773 45.4219 34.8051 45.4219H17.1945C14.2223 45.4219 11.3718 44.2465 9.27017 42.1543C7.1685 40.0621 5.98779 37.2244 5.98779 34.2656V16.7344C5.98779 13.7756 7.1685 10.9379 9.27017 8.84571C11.3718 6.75351 14.2223 5.57813 17.1945 5.57812H34.8051C37.7773 5.57813 40.6278 6.75351 42.7295 8.84571C44.8311 10.9379 46.0118 13.7756 46.0118 16.7344ZM35.6056 25.5C35.6056 23.6087 35.0422 21.7599 33.9867 20.1874C32.9312 18.6148 31.431 17.3892 29.6758 16.6654C27.9206 15.9416 25.9892 15.7523 24.1258 16.1212C22.2625 16.4902 20.5509 17.401 19.2075 18.7383C17.8641 20.0756 16.9493 21.7795 16.5786 23.6344C16.208 25.4894 16.3982 27.4121 17.1252 29.1594C17.8523 30.9067 19.0835 32.4002 20.6631 33.4509C22.2428 34.5017 24.1 35.0625 25.9998 35.0625C28.5474 35.0625 30.9907 34.055 32.7921 32.2617C34.5935 30.4684 35.6056 28.0361 35.6056 25.5ZM38.8075 15.1406C38.8075 14.6678 38.6667 14.2056 38.4028 13.8125C38.1389 13.4193 37.7639 13.1129 37.3251 12.932C36.8862 12.751 36.4034 12.7037 35.9376 12.7959C35.4717 12.8882 35.0438 13.1159 34.708 13.4502C34.3721 13.7845 34.1434 14.2105 34.0508 14.6742C33.9581 15.138 34.0057 15.6186 34.1874 16.0555C34.3692 16.4923 34.677 16.8657 35.0719 17.1284C35.4668 17.391 35.9311 17.5312 36.4061 17.5312C37.043 17.5312 37.6538 17.2794 38.1041 16.8311C38.5545 16.3827 38.8075 15.7747 38.8075 15.1406Z"
			fill="#0B1215"
		/>
	</svg>
	<svg
		v-else-if="name === 'linkedin'"
		xmlns="http://www.w3.org/2000/svg"
		width="52"
		height="51"
		viewBox="0 0 52 51"
		fill="none"
	>
		<path
			d="M26.2308 4.36572C14.5057 4.36572 5.00098 13.8277 5.00098 25.4999C5.00098 37.1722 14.5057 46.6341 26.2308 46.6341C37.9559 46.6341 47.4606 37.1722 47.4606 25.4999C47.4606 13.8277 37.9559 4.36572 26.2308 4.36572ZM21.0339 34.2596H16.7349V20.4872H21.0339V34.2596ZM18.8579 18.7964C17.5 18.7964 16.6221 17.8388 16.6221 16.6544C16.6221 15.4458 17.5266 14.5167 18.9132 14.5167C20.2997 14.5167 21.1489 15.4458 21.1755 16.6544C21.1755 17.8388 20.2997 18.7964 18.8579 18.7964ZM36.7352 34.2596H32.4361V26.6271C32.4361 24.8505 31.8125 23.6441 30.2578 23.6441C29.0703 23.6441 28.3649 24.4608 28.053 25.2468C27.938 25.5263 27.9093 25.9226 27.9093 26.3167V34.2574H23.608V24.8791C23.608 23.1598 23.5528 21.7222 23.4953 20.485H27.2304L27.4272 22.398H27.5134C28.0796 21.4998 29.4661 20.1745 31.7859 20.1745C34.6144 20.1745 36.7352 22.0612 36.7352 26.1163V34.2596Z"
			fill="#0B1215"
		/>
	</svg>
	<svg
		v-else-if="name === 'footer-top'"
		xmlns="http://www.w3.org/2000/svg"
		width="100%"
		height="100%"
		viewBox="0 0 1430 188"
		preserveAspectRatio="xMidYMid meet"
		fill="none"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M0 188H1430V73.8709V186.964H1428.12C1402.42 186.964 1379.63 168.9 1365.45 141.041L1365.09 140.335C1361.07 132.505 1358.1 123.197 1355.13 113.87C1351.27 101.776 1347.4 89.6556 1341.24 80.7045C1330.9 65.6892 1314.13 65.6892 1303.79 80.7045L1303.01 81.8412C1297.54 89.7795 1293.35 99.9825 1289.49 109.706C1288.87 111.276 1288.26 112.882 1287.64 114.493L1287.64 114.499C1285.31 120.635 1282.94 126.875 1279.66 131.631C1272.06 142.672 1259.73 142.672 1252.13 131.631C1250.32 129.019 1248.88 125.906 1247.91 122.474C1246.93 119.042 1246.42 115.358 1246.42 111.638C1246.42 107.917 1246.93 104.234 1247.91 100.802C1248.88 97.3696 1250.32 94.257 1252.13 91.6449C1252.44 91.1931 1252.75 90.7617 1253.08 90.3479C1255.99 86.6461 1259.64 83.762 1263.21 80.9391C1265.06 79.473 1266.97 78.1118 1268.89 76.7477C1272.88 73.903 1276.9 71.0465 1280.42 67.2034C1281.39 66.1339 1282.32 64.9652 1283.19 63.7058C1293.33 48.9849 1293.33 25.1162 1283.19 10.3939C1279.49 5.00908 1274.76 1.38675 1269.63 0H1260.02C1254.9 1.38675 1250.17 5.00908 1246.47 10.3939C1239.96 19.8479 1238.6 33.2746 1237.22 46.8368C1236.01 58.777 1234.79 70.8235 1230.04 80.3562C1220.6 99.2669 1205.27 111.569 1187.96 111.569L0 109.63V188Z"
			fill="#FFFBF2"
		/>
	</svg>
	<svg
		v-else-if="name === 'eclipse'"
		xmlns="http://www.w3.org/2000/svg"
		width="101"
		height="101"
		viewBox="0 0 101 101"
		fill="none"
	>
		<circle
			cx="50.5"
			cy="50.5"
			r="50.5"
			:fill="yellowSecondaryColor"
		/>
		<text
			x="50.5"
			y="72"
			font-family="Spartan"
			font-size="48"
			text-anchor="middle"
			fill="white"
		>VS</text>

	</svg>
	<svg
		v-else-if="name === 'sp-top'"
		xmlns="http://www.w3.org/2000/svg"
		width="100%"
		height="100%"
		viewBox="0 0 1435 188"
		fill="none"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M-89.9999 188H1435V73.8709V186.964H1432.99C1405.59 186.964 1381.29 168.9 1366.16 141.041L1365.78 140.335C1361.49 132.505 1358.33 123.197 1355.15 113.87C1351.04 101.776 1346.92 89.6556 1340.34 80.7045C1329.32 65.6892 1311.43 65.6892 1300.41 80.7045L1299.57 81.8412C1293.74 89.7795 1289.27 99.9825 1285.16 109.706C1284.49 111.276 1283.84 112.882 1283.19 114.493L1283.18 114.499C1280.7 120.635 1278.17 126.875 1274.68 131.631C1266.57 142.672 1253.42 142.672 1245.31 131.631C1243.38 129.019 1241.85 125.906 1240.81 122.474C1239.76 119.042 1239.23 115.358 1239.23 111.638C1239.23 107.917 1239.76 104.234 1240.81 100.802C1241.85 97.3696 1243.38 94.257 1245.31 91.6449C1245.64 91.1931 1245.98 90.7617 1246.33 90.3479C1249.43 86.6461 1253.32 83.762 1257.13 80.9391C1259.1 79.473 1261.14 78.1118 1263.18 76.7477C1267.45 73.903 1271.73 71.0465 1275.48 67.2034C1276.52 66.1339 1277.51 64.9652 1278.43 63.7058C1289.25 48.9849 1289.25 25.1162 1278.43 10.3939C1274.49 5.00906 1269.45 1.38675 1263.98 0H1253.73C1248.26 1.38675 1243.23 5.00906 1239.28 10.3939C1232.33 19.8479 1230.88 33.2746 1229.42 46.8368C1228.13 58.777 1226.83 70.8235 1221.75 80.3562C1211.69 99.2669 1195.34 111.569 1176.88 111.569L-89.9999 109.63V188Z"
			:fill="yellowSecondaryColor"
		/>
	</svg>
	<svg
		v-else-if="name === 'sp-mob-top'"
		xmlns="http://www.w3.org/2000/svg"
		width="100%"
		height="100%"
		viewBox="0 0 390 61"
		fill="none"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M390 61H-1V23.9687V60.6638H-0.485369C6.54013 60.6638 12.7711 54.8026 16.6502 45.7632L16.7488 45.5344C17.8465 42.9937 18.6584 39.9734 19.4719 36.9471C20.5265 33.0232 21.5837 29.0904 23.2694 26.186C26.0969 21.3141 30.6813 21.3141 33.5088 26.186L33.7229 26.5549C35.2179 29.1306 36.3635 32.4411 37.4191 35.5961C37.5894 36.1054 37.7563 36.6265 37.9239 37.1495L37.9245 37.1514C38.5622 39.1421 39.2105 41.167 40.1058 42.7099C42.185 46.2924 45.5562 46.2924 47.6356 42.7099C48.1296 41.8624 48.5218 40.8525 48.7895 39.7388C49.0572 38.6252 49.1951 37.4301 49.1951 36.2229C49.1951 35.0157 49.0572 33.8206 48.7895 32.7069C48.5218 31.5933 48.1296 30.5834 47.6356 29.7358C47.5506 29.5893 47.4636 29.4493 47.3745 29.315C46.5786 28.1139 45.5817 27.1781 44.606 26.2622C44.0995 25.7865 43.5764 25.3448 43.0526 24.9022C41.9595 23.9791 40.8617 23.0523 39.9001 21.8054C39.6326 21.4584 39.3794 21.0791 39.1423 20.6705C36.37 15.894 36.37 8.14941 39.1423 3.3725C40.1547 1.62529 41.4463 0.449959 42.8485 0H45.4757C46.8779 0.449959 48.1695 1.62529 49.182 3.3725C50.9622 6.43999 51.3342 10.7965 51.71 15.197C52.0407 19.0713 52.3744 22.98 53.6751 26.073C56.2547 32.2089 60.4473 36.2004 65.1809 36.2004L390 35.5715V61Z"
			:fill="yellowSecondaryColor"
		/>
	</svg>

	<svg
		v-else-if="name === 'sp-bottom'"
		xmlns="http://www.w3.org/2000/svg"
		width="100%"
		height="100%"
		viewBox="0 0 1440 188"
		fill="none"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M1522 0H-3V114.129V1.03619H-0.992808C26.4084 1.03619 50.7109 19.1002 65.8401 46.9592L66.2248 47.6646C70.5063 55.495 73.6728 64.8032 76.8458 74.1303C80.9587 86.2235 85.0823 98.3444 91.657 107.296C102.685 122.311 120.565 122.311 131.593 107.296L132.428 106.159C138.259 98.2205 142.727 88.0175 146.844 78.294C147.509 76.7244 148.159 75.1184 148.813 73.5065L148.815 73.5007C151.303 67.3652 153.831 61.1248 157.323 56.3694C165.432 45.3284 178.581 45.3284 186.691 56.3694C188.618 58.9815 190.148 62.094 191.192 65.5262C192.236 68.9584 192.774 72.6418 192.774 76.3622C192.774 80.0827 192.236 83.7661 191.192 87.1983C190.148 90.6304 188.618 93.743 186.691 96.3551C186.36 96.8069 186.021 97.2383 185.673 97.6521C182.569 101.354 178.681 104.238 174.875 107.061C172.9 108.527 170.86 109.888 168.816 111.252C164.553 114.097 160.271 116.953 156.521 120.797C155.477 121.866 154.49 123.035 153.565 124.294C142.752 139.015 142.752 162.884 153.565 177.606C157.514 182.991 162.552 186.613 168.02 188H178.267C183.736 186.613 188.774 182.991 192.722 177.606C199.666 168.152 201.117 154.725 202.582 141.163C203.872 129.223 205.174 117.176 210.247 107.644C220.308 88.7331 236.66 76.4315 255.122 76.4315L1522 78.3698V0Z"
			:fill="yellowSecondaryColor"
		/>
	</svg>
	<svg
		v-else-if="name === 'sp-mob-bottom'"
		xmlns="http://www.w3.org/2000/svg"
		width="100%"
		height="100%"
		viewBox="0 0 390 61"
		fill="none"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M-1 0H390V37.0313V0.336209H389.485C382.46 0.336209 376.229 6.19741 372.35 15.2368L372.251 15.4656C371.153 18.0063 370.342 21.0266 369.528 24.0529C368.474 27.9768 367.416 31.9096 365.731 34.814C362.903 39.6859 358.319 39.6859 355.491 34.814L355.277 34.4451C353.782 31.8694 352.637 28.5589 351.581 25.4039C351.411 24.8946 351.244 24.3735 351.076 23.8505L351.076 23.8486C350.438 21.8579 349.789 19.833 348.894 18.2901C346.815 14.7076 343.444 14.7076 341.364 18.2901C340.87 19.1376 340.478 20.1475 340.21 21.2612C339.943 22.3748 339.805 23.5699 339.805 24.7771C339.805 25.9843 339.943 27.1794 340.21 28.2931C340.478 29.4067 340.87 30.4166 341.364 31.2642C341.449 31.4107 341.536 31.5507 341.625 31.685C342.421 32.8861 343.418 33.8219 344.394 34.7378C344.901 35.2135 345.424 35.6552 345.947 36.0978C347.041 37.0209 348.138 37.9477 349.1 39.1946C349.367 39.5416 349.621 39.9209 349.858 40.3295C352.63 45.106 352.63 52.8506 349.858 57.6275C348.845 59.3747 347.554 60.55 346.151 61H343.524C342.122 60.55 340.83 59.3747 339.818 57.6275C338.038 54.56 337.666 50.2035 337.29 45.803C336.959 41.9287 336.626 38.02 335.325 34.927C332.745 28.7911 328.553 24.7996 323.819 24.7996L-1 25.4285V0Z"
			:fill="yellowSecondaryColor"
		/>
	</svg>
	<svg
		v-else-if="name === 'personalization'"
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
	>
		<g clip-path="url(#clip0_1753_5944)">
			<path
				d="M10.3333 0H8.66667C7.5625 0 6.66667 1.00781 6.66667 2.25V7.5C6.66667 9.15469 7.8625 10.5 9.33333 10.5H14.6667C16.1375 10.5 17.3333 9.15469 17.3333 7.5V2.25C17.3333 1.00781 16.4375 0 15.3333 0H13.6667V3.75C13.6667 4.1625 13.3667 4.5 13 4.5H11C10.6333 4.5 10.3333 4.1625 10.3333 3.75V0ZM2.66667 12C1.19583 12 0 13.3453 0 15V21C0 22.6547 1.19583 24 2.66667 24H9.33333C10.8042 24 12 22.6547 12 21V15C12 13.3453 10.8042 12 9.33333 12H7.66667V15.75C7.66667 16.1625 7.36667 16.5 7 16.5H5C4.63333 16.5 4.33333 16.1625 4.33333 15.75V12H2.66667ZM14.6667 24H21.3333C22.8042 24 24 22.6547 24 21V15C24 13.3453 22.8042 12 21.3333 12H19.6667V15.75C19.6667 16.1625 19.3667 16.5 19 16.5H17C16.6333 16.5 16.3333 16.1625 16.3333 15.75V12H14.6667C14.0417 12 13.4667 12.2391 13.0125 12.6469C13.2167 13.1344 13.3333 13.6781 13.3333 14.25V21.75C13.3333 22.3219 13.2167 22.8656 13.0125 23.3531C13.4667 23.7609 14.0417 24 14.6667 24Z"
				fill="black" 
			/>
		</g>
		<defs>
			<clipPath id="clip0_1753_5944">
				<rect
					width="24"
					height="24"
					fill="white"
				/>
			</clipPath>
		</defs>
	</svg>
	<svg
		v-else-if="name === 'hs-bottom'"
		xmlns="http://www.w3.org/2000/svg"
		width="100%"
		height="100%"
		viewBox="0 0 1432 188"
		fill="none"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M1440 0H0V114.129V1.03619H1.89532C27.7693 1.03619 50.7172 19.1002 65.0031 46.9592L65.3664 47.6646C69.4092 55.495 72.3993 64.8032 75.3954 74.1303C79.2791 86.2235 83.1728 98.3444 89.3811 107.296C99.7943 122.311 116.678 122.311 127.091 107.296L127.88 106.159C133.386 98.2205 137.605 88.0175 141.492 78.294C142.12 76.7244 142.734 75.1184 143.352 73.5065L143.354 73.5007C145.702 67.3652 148.09 61.1248 151.387 56.3694C159.044 45.3284 171.46 45.3284 179.118 56.3694C180.938 58.9815 182.382 62.094 183.368 65.5262C184.354 68.9584 184.862 72.6418 184.862 76.3622C184.862 80.0827 184.354 83.7661 183.368 87.1983C182.382 90.6304 180.938 93.743 179.118 96.3551C178.805 96.8069 178.485 97.2383 178.157 97.6521C175.226 101.354 171.554 104.238 167.961 107.061C166.095 108.527 164.169 109.888 162.24 111.252C158.214 114.097 154.171 116.953 150.63 120.797C149.644 121.866 148.712 123.035 147.839 124.294C137.629 139.015 137.629 162.884 147.839 177.606C151.567 182.991 156.324 186.613 161.488 188H171.164C176.328 186.613 181.085 182.991 184.813 177.606C191.37 168.152 192.74 154.725 194.124 141.163C195.342 129.223 196.57 117.176 201.361 107.644C210.861 88.7331 226.302 76.4315 243.735 76.4315L1440 78.3698V0Z"
			:fill="yellowSecondaryColor"
		/>
	</svg>
	<svg
		v-else-if="name === 'check'"
		xmlns="http://www.w3.org/2000/svg"
		width="32"
		height="33"
		viewBox="0 0 32 33"
		fill="none"
	>
		<path
			d="M15.9998 3.16666C23.3636 3.16666 29.3332 9.13619 29.3332 16.5C29.3332 23.8637 23.3636 29.8333 15.9998 29.8333C8.63604 29.8333 2.6665 23.8637 2.6665 16.5C2.6665 9.13619 8.63604 3.16666 15.9998 3.16666ZM20.2928 12.4596L14.3332 18.4191L11.7069 15.7929C11.3164 15.4024 10.6833 15.4024 10.2927 15.7929C9.90221 16.1835 9.90221 16.8165 10.2927 17.2071L13.6261 20.5404C14.0166 20.9309 14.6497 20.9309 15.0402 20.5404L21.7069 13.8737C22.0974 13.4832 22.0974 12.8501 21.7069 12.4596C21.3164 12.069 20.6833 12.069 20.2928 12.4596Z"
			:fill="yellowSecondaryColor"
		/>
	</svg>
	<svg 
		v-else-if="name === 'More'"
		width="40" 
		height="40" 
		viewBox="0 0 40 40" 
		fill="none" 
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect 
			width="40" 
			height="40" 
			rx="20" 
			:fill="blueColorHex"
		/>
		<path 
			d="M19.9223 14.0045L20 14C20.3419 14 20.6237 14.2574 20.6622 14.5889L20.6667 14.6667V19.3333H25.3333C25.6752 19.3333 25.957 19.5907 25.9955 19.9223L26 20C26 20.3419 25.7427 20.6237 25.4111 20.6622L25.3333 20.6667H20.6667V25.3333C20.6667 25.6752 20.4093 25.957 20.0777 25.9955L20 26C19.6581 26 19.3763 25.7427 19.3378 25.4111L19.3333 25.3333V20.6667H14.6667C14.3248 20.6667 14.043 20.4093 14.0045 20.0777L14 20C14 19.6581 14.2574 19.3763 14.5889 19.3378L14.6667 19.3333H19.3333V14.6667C19.3333 14.3248 19.5907 14.043 19.9223 14.0045Z"
			fill="black"
		/>
	</svg>
	<svg 
		v-else-if="name === 'g-auth'"
		xmlns="http://www.w3.org/2000/svg" 
		x="0px" 
		y="0px" 
		width="20" 
		height="20" 
		viewBox="0 0 48 48"
	>
		<path
			fill="#FFC107"
			d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
		/>
		<path
			fill="#FF3D00"
			d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
		/>
		<path
			fill="#4CAF50"
			d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
		/>
		<path
			fill="#1976D2"
			d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
		/>
	</svg>
	<svg 
		v-else-if="name === 'f-auth'"
		xmlns="http://www.w3.org/2000/svg"
		x="0px"
		y="0px"
		width="20"
		height="20"
		viewBox="0 0 48 48"
	>
		<path
			fill="#039be5"
			d="M24 5A19 19 0 1 0 24 43A19 19 0 1 0 24 5Z"
		/><path
			fill="#fff"
			d="M26.572,29.036h4.917l0.772-4.995h-5.69v-2.73c0-2.075,0.678-3.915,2.619-3.915h3.119v-4.359c-0.548-0.074-1.707-0.236-3.897-0.236c-4.573,0-7.254,2.415-7.254,7.917v3.323h-4.701v4.995h4.701v13.729C22.089,42.905,23.032,43,24,43c0.875,0,1.729-0.08,2.572-0.194V29.036z"
		/>
	</svg>
	<svg
		v-else-if="name === 'filters'"
		xmlns="http://www.w3.org/2000/svg"
		width="17"
		height="16"
		viewBox="0 0 17 16"
		fill="none"
	>
		<g id="icons/mage:filter">
			<path
				id="Vector"
				d="M14.6666 8.00009H6.42992M3.52259 8.00009H2.33325M3.52259 8.00009C3.52259 7.61464 3.6757 7.24498 3.94826 6.97243C4.22081 6.69988 4.59047 6.54676 4.97592 6.54676C5.36137 6.54676 5.73103 6.69988 6.00358 6.97243C6.27613 7.24498 6.42925 7.61464 6.42925 8.00009C6.42925 8.38554 6.27613 8.7552 6.00358 9.02775C5.73103 9.3003 5.36137 9.45342 4.97592 9.45342C4.59047 9.45342 4.22081 9.3003 3.94826 9.02775C3.6757 8.7552 3.52259 8.38554 3.52259 8.00009ZM14.6666 12.4048H10.8346M10.8346 12.4048C10.8346 12.7903 10.6811 13.1604 10.4085 13.433C10.1359 13.7056 9.76612 13.8588 9.38059 13.8588C8.99514 13.8588 8.62548 13.705 8.35292 13.4324C8.08037 13.1599 7.92725 12.7902 7.92725 12.4048M10.8346 12.4048C10.8346 12.0192 10.6811 11.6498 10.4085 11.3772C10.1359 11.1046 9.76612 10.9514 9.38059 10.9514C8.99514 10.9514 8.62548 11.1045 8.35292 11.3771C8.08037 11.6496 7.92725 12.0193 7.92725 12.4048M7.92725 12.4048H2.33325M14.6666 3.59542H12.5966M9.68925 3.59542H2.33325M9.68925 3.59542C9.68925 3.20998 9.84237 2.84031 10.1149 2.56776C10.3875 2.29521 10.7571 2.14209 11.1426 2.14209C11.3334 2.14209 11.5224 2.17968 11.6988 2.25272C11.8751 2.32576 12.0353 2.43281 12.1702 2.56776C12.3052 2.70272 12.4123 2.86293 12.4853 3.03926C12.5583 3.21558 12.5959 3.40457 12.5959 3.59542C12.5959 3.78628 12.5583 3.97526 12.4853 4.15159C12.4123 4.32792 12.3052 4.48813 12.1702 4.62308C12.0353 4.75804 11.8751 4.86509 11.6988 4.93813C11.5224 5.01117 11.3334 5.04876 11.1426 5.04876C10.7571 5.04876 10.3875 4.89564 10.1149 4.62308C9.84237 4.35053 9.68925 3.98087 9.68925 3.59542Z"
				stroke="black"
				stroke-width="1.5"
				stroke-miterlimit="10"
				stroke-linecap="round"
			/>
		</g>
	</svg>
</template>
<script>
import FormuloWebConstants from '@/config/formuloWebConstants'
export default {
	name: 'IconSection',
	props: ['name'],
	data(){
		return {
			blueColorHex: FormuloWebConstants.blueColorHex,
			yellowSecondaryColor: FormuloWebConstants.yellowSecondaryColorHex
		}
	}
}
</script>
<style></style>