<template>
	<swiper-container
		class="swiper-container mySwiper"
		style="--swiper-navigation-color: #000;" 
		thumbs-swiper=".mySwiper2"
		space-between="10"
		effect="fade"
		navigation="true"
	>
		<swiper-slide 
			v-for="(image, index) in product.product_images_1" 
			:key="index"
			class="d-flex justify-content-center align-items-center bg-white "
		>
			<img 
				class="img-fluid object-fit-cover rounded-4 product-lead-image" 
				:alt="product.product_name"
				:src="image"
			>
		</swiper-slide>
	</swiper-container>
	<swiper-container
		class="swiper-container mySwiper2 mt-3"
		style="--swiper-navigation-color: #000;"
		space-between="10" 
		slides-per-view="4"
	>
		<swiper-slide 
			v-for="(image, index) in product.product_images_1" 
			:key="index"
			class="h-100"
		>
			<img 
				class="product-group-images img-fluid object-fit-cover rounded-4" 
				:alt="product.product_name"
				:src="image"
				loading="lazy"
			>
		</swiper-slide>
	</swiper-container>
</template>

<script>
import { Swiper } from '@/config/swiper.js';
export default {
	name:'CarouselV2',
	props:{
		product:{
			type:Object,
			required:true
		}
	},
	setup() {
		return {
			Swiper
		};
	},
};
</script>

<style scoped>

.mySwiper2 swiper-slide {
      opacity: 0.4;
}
.mySwiper2 .swiper-slide-thumb-active {
	opacity: 1;
}
.product-group-images{
	min-height: 100%;
	min-width: 100%;
}
.product-lead-image{
	min-height: 100%;
	min-width: 100%;
}
</style>
