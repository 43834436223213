<template>
	<div class="text-center my-4">
		<h3>Sign Up</h3>
	</div>
	<div class="d-flex justify-content-center my-4">
		<form class="col-10 col-sm-8">
			<OtpInput 
				v-if="!showQuiz"
				:length="4"
				:tabs-data="tabsData"
				@go-to-quiz="goToQuiz"
				@go-to-tab="handleTabSwitch"
			/>
			<div v-else>
				<div class="form-group my-3 ">
					<label class="h6 pb-1">Full Name</label>
					<input 
						v-model="state.form.fullName"
						type="name" 
						class="form-control box border-black"
						placeholder="Full name"
					>
					<div 
						v-if="v$.form.fullName.$invalid && v$.form.fullName.$dirty" 
						class="text-danger"
					>
						{{ v$.form.fullName.$errors[0].$message }}
					</div>
				</div>
				<div class="form-group my-3">
					<label class="h6 pb-1">Email Address</label>
					<input 
						v-model="state.form.email"
						type="email" 
						class="form-control box border-black p-2"
						placeholder="E-mail"
					>
					<div 
						v-if="v$.form.email.$invalid && v$.form.email.$dirty" 
						class="text-danger"
					>
						{{ v$.form.email.$errors[0].$message }}
					</div>
				</div>	
				<div class="form-group my-3">
					<label class="h6 pb-1">Password</label>
					<div 
						class="row-10 form-control box border-black d-flex align-items-center"
					>
						<input 
							v-model="state.form.password.password" 
							:type="visibilty" 
							class="col-11 border-0 outline p-0" 
							placeholder="Enter Password"
						>
						<font-awesome-icon 	
							v-if="visibilty==='password'" 
							:icon="['fas', 'eye-slash']"
							class="ms-auto"
							style="cursor: pointer;"
							@click="showPassword()"
						/>
						<font-awesome-icon 	
							v-if="visibilty==='text'" 
							:icon="['fas', 'eye']"
							class="ms-auto"
							style="cursor: pointer;"
							@click="hidePassword()"
						/>
					</div>		
					<div 
						v-if="v$.form.password.password.$invalid && v$.form.password.password.$dirty" 
						class="text-danger"
					>
						{{ v$.form.password.password.$errors[0].$message }}
					</div>
				</div>
				<div class="form-check my-3">
					<input 
						type="checkbox" 
						class="form-check-input border-success"
					>
					<label class="form-check-label p">Remember me</label>
				</div>
				<div class="form-check my-3">
					<input 
						v-model="state.form.terms"
						type="checkbox" 
						class="form-check-input border-success"
					>
					<label class="form-check-label p">By creating an account, you agree to the 
						<span style="cursor: pointer; text-decoration: underline;">Terms of use and Privacy Policy.</span> </label>
					<div 
						v-if="v$.form.terms.$invalid && v$.form.terms.$dirty" 
						class="text-danger"
					>
						Please select the checkbox to continue.
					</div>
				</div>
				<button 
					type="button" 
					class="btn btn-warning btn-lg rounded-4 p-2 col-12 my-3 h6"
					@click="submitForm()"
				>
					Sign Up
				</button>
				<div class="d-flex justify-content-center pt-4">
					<p class="text-center">
						Already have an account? 
						<span 
							class="bold" 
							style="cursor: pointer;"
							@click="goToSignIn"
						>Log In
						</span>
					</p>
				</div>
			</div>
		</form>
	</div>
	<div class="d-flex justify-content-center my-4">
		<div class="col-10 col-sm-8 d-flex align-items-center">
			<div class="col-4 border-2 border-top p-1" />
			<div class="col-4 text-center">
				<h5>OR</h5>
			</div>
			<div class="col-4 border-2 border-top p-1 " />
		</div>
	</div>
	<GoogleAuthenticationButton :buttons="googlebuttonData" />
	<FacebookAuthenticationButton :buttons="facebookbuttonData" />
</template>
<script>
import { ref, computed } from 'vue';
import {useVuelidate} from '@vuelidate/core';
import OtpInput from '@/Authentication/components/OtpInput.vue';
import { required, email, minLength,sameAs } from '@vuelidate/validators'
import GoogleAuthenticationButton from '@/common/components/buttons/googleAuthenticationButton.vue';
import FacebookAuthenticationButton from '@/common/components/buttons/facebookAuthenticationButton.vue';
import { AuthModeEnum, AuthTypeEnum } from '@/config/formuloWebConstants';

export default {
	components: {
		GoogleAuthenticationButton,
		FacebookAuthenticationButton,
		OtpInput
	},
	emits: ['go-to-tab'],
	setup(){
		const state = ref({
			form:{
				fullName:'',
				email:'',
				contactNumber:'',
				password:{
					password:''
				},
				terms: false
			}
		})

		const rules = computed(function(){
			return{
				form:{
					fullName:{required},
					email:{required,email},
					contactNumber:{required,minLength:minLength(10)},
					password:{
						password:{required, minLength:minLength(6)}
					},
					terms: { sameAs: sameAs(true) }

				}
			}
		})

		const v$ = useVuelidate(rules,state)

		return { 
			state,
			v$
		}

	},
	data() {
		return {
			visibilty: 'password',
			showQuiz:false,
			googlebuttonData:{
				name: AuthModeEnum.GOOGLE_AUTH,
				type: AuthTypeEnum.SIGN_UP,
				text: "Sign Up with Google"
			},
			facebookbuttonData:{
				name: AuthModeEnum.FACEBOOK_AUTH,
				type: AuthTypeEnum.SIGN_UP,
				text: "Sign Up with Facebook"
			},
			tabsData:{
				tab:'Sign Up',
				text:'Already have an account?',
				tabSwitch:'Sign In',
			}
		}
	},
	methods: {
		showPassword(){
			this.visibilty='text';
		},
		hidePassword() {
			this.visibilty='password';
		},
		submitForm(){
			this.v$.$validate();
		},
		goToQuiz(){
			this.showQuiz=true;
		},
		handleTabSwitch(tabName) {
			this.$emit('go-to-tab', tabName);
		}

	}
}
</script>
<style>
input::-ms-reveal,
input::-ms-clear {
display: none;
}
.icon-height{
	min-height: 4.2vh;
}
.box{
	min-height: 5vh;
	background-color: #FFFBF2;
}
.outline{
	outline: none;
}
.flag{
	max-height: 2vh;
}
input{
	text-decoration: none !important;
}
</style>