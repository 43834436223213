import axios from 'axios'
 
class AllProductsService {

	static getAllProducts(){
		return axios.get('/json/products.json');     
	}

	static getProductById(product_Id) {
		return axios.get('/json/products.json')
			.then(response => {
				
				const product = response.data.find(p => {
					p.product_id === product_Id});
				if (product) {
					return product;
				} else {
					throw new Error('Product not found');
				}
			});
	}
}

export default AllProductsService;