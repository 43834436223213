<template>
	<div 
		id="whyformulo" 
		class="py-5"
	>
		<div class="container">
			<div class="row px-2 py-5">
				<h2 class="text-center">
					Why <Logo /> ?
				</h2>
			</div>
			<div class="row px-4 justify-content-center gap-5 pb-5 align-items-center">
				<div class="col-12 col-sm-4 text-center">
					<img 
						src="/images/Hero-whyformulo.png" 
						alt="Hero" 
						class="img-fluid object-fit-cover"
					>
				</div>
				<div class="col-12 col-lg-5 col-sm-5 ">
					<h4>
						It's truly a game-changer in skincare
					</h4>
					<p class="mb-0">
						"As a dermatologist, I highly value this service for its commitment to personalized care. It allows me to tailor treatments using clinically tested products, ensuring top-quality results for my patients. The added convenience of direct delivery makes it an essential tool in modern skincare."
					</p>
					<h5 class="pt-4">
						Author Name
					</h5>
					<p>
						Doctor
					</p>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Logo from '@/common/components/Logo.vue';
export default {
	name: "PersonalizedTreatmentSection",
	components: {
		Logo
	}
}
</script>
<style>
.bg-color{
	background-color: var(--yellow-secondary-color-variant)
}
</style>