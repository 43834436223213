import { createApp } from 'vue'
import App from './App.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import '@/config/fa.config' 
import 'bootstrap';
import router from '@/common/router/index.js'

// Import the custom Bootstrap file
import "@/assets/scss/custom-bootstrap.scss";
import "@/assets/scss/fonts.scss";
import { cookiesPlugin } from '@/config/cookiesPlugin';


window.addEventListener("load", () => {
	fetch("/env")
		.then(response => response.json())
		.then(envConfig => {
			const app = createApp(App)
			app.use(router).use(cookiesPlugin())
				.component('font-awesome-icon', FontAwesomeIcon)
				.mount('#app')

			
			app.config.globalProperties.$config = envConfig

		})
		.catch(err => {
			console.error('Error fetching configuration:', err);
		} )

})