<template>
	<div class="text-center my-4">
		<h3>Sign In</h3>
	</div>

	<div class="d-flex justify-content-center my-4">
		<form class="col-10 col-sm-8">
			<OtpInput 
				:length="4"
				:tabs-data="tabsData"
				@go-to-tab="handleTabSwitch"
			/>
		</form>
	</div>
	<div class="d-flex justify-content-center my-4">
		<div class="col-10 col-sm-8 d-flex align-items-center">
			<div class="col-4 border-2 border-top p-1" />
			<div class="col-4 text-center">
				<h5>OR</h5>
			</div>
			<div class="col-4 border-2 border-top p-1 " />
		</div>
	</div>
	<GoogleAuthenticationButton :buttons="googlebuttonData" />
	<FacebookAuthenticationButton :buttons="facebookbuttonData" />
</template>
<script>

import OtpInput from './OtpInput.vue';
import GoogleAuthenticationButton from '@/common/components/buttons/googleAuthenticationButton.vue';
import FacebookAuthenticationButton from '@/common/components/buttons/facebookAuthenticationButton.vue';
import { AuthModeEnum, AuthTypeEnum } from '@/config/formuloWebConstants';
export default {
	components: {
		GoogleAuthenticationButton,
		FacebookAuthenticationButton,
		OtpInput
	},
	emits: ['go-to-tab'],
	data() {
		return {
			googlebuttonData:{
				name: AuthModeEnum.GOOGLE_AUTH,
				type: AuthTypeEnum.SIGN_IN,
				text: "Sign In with Google"
			},
			facebookbuttonData:{
				name: AuthModeEnum.FACEBOOK_AUTH,
				type: AuthTypeEnum.SIGN_IN,
				text: "Sign In with Facebook"
			},
			tabsData:{
				tab:'Sign In',
				tabSwitch:'Sign Up',
				text:'Dont have an account?'
			}
		}
	},
	methods:{
		handleTabSwitch(tabName) {
			this.$emit('go-to-tab', tabName);
		}
	}

}
</script>
<style>

.flag{
	max-height: 2vh;
}
</style>