<template>
	<WhyFormulo />
	<PersonalizedTreatmentSection />
	<StandardizedSection />
	<ReviewsSection />
</template>
<script>
import PersonalizedTreatmentSection from '@/WhyFormulo/components/PersonalizedTreatmentSection.vue';
import WhyFormulo from '@/WhyFormulo/components/WhyFormulo.vue';
import StandardizedSection from '@/WhyFormulo/components/StandardizedSection.vue';
import ReviewsSection from '@/WhyFormulo/components/ReviewsSection.vue';
export default {
	name: "WhyFormuloPage",
	components: {
		PersonalizedTreatmentSection,
		WhyFormulo,
		StandardizedSection,
		ReviewsSection
	}
}
</script>
<style>
</style>