<template>
	<Teleport to="body">
		<div
			:id="modalId"
			class="modal fade"
			tabindex="-1"
		>
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header border-0">
						<button
							type="button"
							class="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
						/>
					</div>
					<div class="modal-body">
						<ErrorAlert :message="message" />
					</div>
				</div>
			</div>
		</div>
	</Teleport>
</template>
<script>
import ErrorAlert from '@/common/components/ErrorAlert.vue'
export default {
	components: { ErrorAlert },
	props: ['modalId', 'message']
}
</script>
