<template>
	<div class="container">
		<div
			id="hero-section"
			class="row"
		>
			<div
				id="hero-img-container"
				class="col-md-12 col-lg-6 overflow-hidden"
			>
				<picture>
					<source
						media="(min-width:768px)"
						srcset="images/hero-3.png"
					>
					<source
						media="(min-width:576px)"
						srcset="images/hero-2.png"
					>
					<img
						class="hero-img w-100 rounded-4"
						src="images/hero-1.png"
					>
				</picture>
			</div>
			<div
				id="hero-text"
				class="col-md-12 col-lg-6 py-md-4"
			>
				<div class="row text-container p-4">
					<h1 class="d-block">
						Meet <span class="fade-border-bottom"> <Logo :size="'h1'" /></span>
					</h1>  
					<h3 class="py-4">
						Skincare developed by Dermatologist for YOU.
					</h3>
					<div class="d-flex justify-content-end m-3">
						<router-link :to="{ path: '/authentication', query: { tab: 'Sign Up' } }">
							<button
								class="p-3 btn btn-warning bold btn-lg text-black rounded-4 my-4"
							>
								Get Prescription
							</button>
						</router-link>
					</div>
				</div>
				<div class="row p-4 text-container">
					<h1 class="p-1 my-2 lh-base">
						<span class="fade-border-bottom">Personalized</span> treatment
					</h1>	
					<h3 class="p-1 mb-5 hide-mobile">
						Formulated by experts
					</h3>
					<div class="d-flex align-items-center my-2 p-2 hero-specialization">
						<img
							alt="acne"
							src="/images/personalization.png"
						><h5 class="px-4">
							Personalized for your skin 
						</h5>
					</div>
					<div class="d-flex align-items-center my-2 p-2 hero-specialization">
						<img
							alt="acne"
							src="/images/quality.png"
							class="img-fluid"
						><h5 class="px-4">
							Dermatologist approved, tested ingridients
						</h5>
					</div>
					<div class="d-flex align-items-center my-2 p-2 hero-specialization">
						<img
							class="img-fluid"
							alt="acne"
							src="/images/convenience.png"
						><h5 class="px-4">
							Manage appointments at your convenience
						</h5>
					</div>
				</div>
				<div 
					class="row p-4" 
					style="height: 10vh"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import gsap from '@/config/gsapEffects'
import Logo from '@/common/components/Logo.vue'
import FormuloWebConstants from '@/config/formuloWebConstants'
export default {
	name: "HeroSection",
	components: {
		Logo
	},
	data() {
		return {
		}
	},
	mounted(){
		let fadeBorderItems = gsap.utils.toArray(".fade-border-bottom")
		fadeBorderItems.forEach(item => {
			gsap.to(item, { borderBottom: "1vh solid #FDD51E", duration: 1, delay: 0.5, scrollTrigger: {trigger: item, start: "center bottom"}})

		})

		gsap.effects.fadeIn(".hero-specialization", {
			duration: 1.5,
			scrollTrigger: {
				trigger: ".hero-specialization",
				start: "top 90%",
			},
			stagger: 0.2
		})

		let mm = gsap.matchMedia()

		let mdMaxBreakpoint = FormuloWebConstants.customMdMaxWidthBreakpoint;
		let lgMinBreakpoint = FormuloWebConstants.customLgMinWidthBreakpoint
		
		mm.add(`(min-width: ${lgMinBreakpoint})`, () => {
		

			gsap.timeline({
				scrollTrigger: {
					trigger: "#hero-section",
					start: "top 12%",
					end: "bottom bottom",
					pin: "#hero-img-container",
					pinSpacer: false,
				}

			})

		});

		mm.add(`(max-width: ${mdMaxBreakpoint})`, () => {
			gsap.effects.fadeIn("#hero-text", {duration: 1, scrollTrigger: {trigger: "#hero-section"}})
		})
		
	}

}
</script>
<style>

.text-container{
	display:flex;
	flex-direction:column;
}

.hero-img {
	object-position: 30% 50%;
	object-fit: cover;
	height: 100%;
}

@media (max-width: 1024px) {
	.text-container{
		justify-content: start;
	}
	#hero-img-container {
		height: 50vh;
	}
	.hide-mobile {
		display: none !important;
	}
	
}

@media (min-width: 1025px) {
	.text-container{
		min-height: 100vh;
		justify-content: center;
	}
	#hero-img-container {
		height: 80vh;
	}
}

</style>