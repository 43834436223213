<template lang="">
	<div class="container">
		<div class="row">
			<h5>Customers Feedback</h5>
		</div>
		<div class="row bg bg-light mb-4">
			<div class="col-sm-4 col-12 h-100 text-center py-3">
				<h2>4.8</h2>
				<div class="mb-2">
					<font-awesome-icon
						v-for="n in 5"
						:key="n"
						:icon="['far', 'star']"
						class="text-warning px-1"
					/>
				</div>
				<p>Product Rating</p>
			</div>
			<div class="col-sm col-12 d-flex align-items-center">
				<table class="table table-borderless table-light">
					<tbody>
						<tr class="d-flex align-items-center">
							<td
								colspan="2"
								class="col-sm-6 col-4"
							>
								<div class="progress">
									<div
										class="progress-bar"
										role="progressbar"
										style="width: 70%"
										aria-valuenow="70"
										aria-valuemin="0"
										aria-valuemax="100"
									/>
								</div>
							</td>
							<td class="col-6 col-sm-4 text-center">
								<font-awesome-icon
									v-for="n in 5"
									:key="n"
									:icon="['far', 'star']"
									class="text-warning px-1"
									size="sm"
								/>
							</td>
							<td class="col text-center">
								70%
							</td>
						</tr>
						<tr class="d-flex align-items-center">
							<td
								colspan="2"
								class="col-sm-6 col-4"
							>
								<div class="progress">
									<div
										class="progress-bar"
										role="progressbar"
										style="width: 70%"
										aria-valuenow="70"
										aria-valuemin="0"
										aria-valuemax="100"
									/>
								</div>
							</td>
							<td class="col-6 col-sm-4 text-center">
								<font-awesome-icon
									v-for="n in 5"
									:key="n"
									:icon="['far', 'star']"
									class="text-warning px-1"
									size="sm"
								/>
							</td>
							<td class="col text-center">
								70%
							</td>
						</tr>
						<tr class="d-flex">
							<td
								colspan="2"
								class="col-sm-6 col-4"
							>
								<div class="progress">
									<div
										class="progress-bar"
										role="progressbar"
										style="width: 70%"
										aria-valuenow="70"
										aria-valuemin="0"
										aria-valuemax="100"
									/>
								</div>
							</td>
							<td class="col-6 col-sm-4 text-center">
								<font-awesome-icon
									v-for="n in 5"
									:key="n"
									:icon="['far', 'star']"
									class="text-warning px-1"
									size="sm"
								/>
							</td>
							<td class="col text-center">
								70%
							</td>
						</tr>
						<tr class="d-flex">
							<td
								colspan="2"
								class="col-sm-6 col-4"
							>
								<div class="progress">
									<div
										class="progress-bar"
										role="progressbar"
										style="width: 70%"
										aria-valuenow="70"
										aria-valuemin="0"
										aria-valuemax="100"
									/>
								</div>
							</td>
							<td class="col-6 col-sm-4 text-center">
								<font-awesome-icon
									v-for="n in 5"
									:key="n"
									:icon="['far', 'star']"
									class="text-warning px-1"
									size="sm"
								/>
							</td>
							<td class="col text-center">
								70%
							</td>
						</tr>
						<tr class="d-flex">
							<td
								colspan="2"
								class="col-sm-6 col-4"
							>
								<div class="progress">
									<div
										class="progress-bar"
										role="progressbar"
										style="width: 70%"
										aria-valuenow="70"
										aria-valuemin="0"
										aria-valuemax="100"
									/>
								</div>
							</td>
							<td class="col-6 col-sm-4 text-center">
								<font-awesome-icon
									v-for="n in 5"
									:key="n"
									:icon="['far', 'star']"
									class="text-warning px-1"
									size="sm"
								/>
							</td>
							<td class="col text-center">
								70%
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div class="row">
			<h5>Reviews</h5>
		</div>
		<div class="row">
			<div class="col-3 d-flex justify-content-center">
				<div class="">
					<img 
						class="user-height img-fluid rounded-circle border border-warning"
						src="/images/cleanser.png" 
						alt="cleanser"
					>
				</div>
			</div>
			<div class="col ">
				<div class="d-flex justify-content-between mb-2">
					<h6 class="col-7">
						Gaurav mekhe
					</h6>
					<p>3 days ago</p>
				</div>
				<div class="mb-2">
					<font-awesome-icon
						v-for="n in 5"
						:key="n"
						:icon="['far', 'star']"
						class="text-warning px-1"
						size="sm"
					/>
				</div>
				<p>Great Product</p>
				<p class="pb-3">
					There are many variations of passages of Lorem Ipsum available, but tde majority have suffered alteration in some form, by injected humour
				</p>
				<div class="d-flex mb-2">
					<font-awesome-icon
						:icon="['fas', 'thumbs-up']"
						size="lg"
					/>
					<p class="ps-4">
						Reply
					</p>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	
}
</script>
<style>
.user-height{
	max-height: 50%;
}
</style>