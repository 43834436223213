<template>
	<div
		id="standardized-section"
		class="bg-light my-5 "
	>
		<div
			class="container-lg py-5"
		>
			<div class="row py-5">
				<h2 class="d-flex justify-content-start">
					Standardize Experience Section 
				</h2>
			</div>
			<div id="standardized-cards">
				<CarouselV1
					class="row"
					:card-names="cardNames"
					:show-direction-controls="true"
				>
					<template #expertise>
						<div class="col ms-2 h-100">
							<div 
								class="card h-100 border-dark rounded-5"
							>
								<div class="card-body">
									<div class="text-center">
										<img
											class="img-fluid" 
											alt="Expertise" 
											src="/images/Expertise.png" 
										>
									</div>
									<h5 class="card-title">
										Expertise
									</h5>
									<p class="card-text">
										All our doctors have a minimum of 3 years of experience, ensuring you receive reliable, professional care every time.
									</p>
								</div>
							</div>
						</div>
					</template>
					<template #professionals>
						<div class="col ms-2 h-100">
							<div 
								class="card h-100 border-dark rounded-5 standardized-card"
							>
								<div class="card-body">
									<div class="text-center">
										<img 
											class="img-fluid" 
											alt="Professionals" 
											src="/images/Professionals.png" 
										>
									</div>
									<h5 class="card-title">
										Excellence
									</h5>
									<p class="card-text">
										Our team consists of fully certified doctors, providing you with trustworthy and expert care.
									</p>
								</div>
							</div>
						</div>
					</template>
					<template #assesment>
						<div class="col ms-2 h-100">
							<div 
								class="card h-100 border-dark rounded-5"
							>
								<div class="card-body">
									<div class="text-center">
										<img 
											class="img-fluid" 
											alt="Assesment" 
											src="/images/Assesment.png" 
										>
									</div>
									<h5 class="card-title">
										Evaluation
									</h5>
									<p class="card-text">
										Our doctors conduct thorough evaluations to deliver precise and effective treatment plans.
									</p>
								</div>
							</div>
						</div>	
					</template>
				</CarouselV1>
			</div>
		</div>
	</div>
</template>

<script>
import CarouselV1 from '@/common/components/carousel/Carousel.vue';
import gsap from '@/config/gsapEffects';
export default {
	name: "StandardizedSection",
	components:{
		CarouselV1
	},
	data(){
		return {
			cardNames:['expertise','professionals','assesment']
		}
	},
	mounted(){
		gsap.effects.fadeIn("#standardized-cards .card", {duration: 1.5, stagger: 0.4, scrollTrigger: {
			trigger: "standardized-section",
			start: "top 40%",
		}})

	}
}
</script>
<style>

</style>