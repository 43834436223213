<template>
	<div
		id="what-to-expect-section" 	
		class="my-5 bg-light" 
	>
		<div 
			class="container-lg py-4"
		>
			<div class="row ms-3 py-5">
				<h2 class="d-flex justify-content-start">
					What To Expect During Treatment
				</h2>
			</div>
			<div id="what-to-expect-cards">
				<CarouselV1
					class="row "
					:card-names="cardNames"
					:show-direction-controls="true"
				>
					<template #chatting>
						<div class="col ms-2 h-100">
							<div 
								class="card h-100 border-0 rounded-5"
							>
								<div class="card-body">
									<div class="text-center">
										<img
											class="img-fluid" 
											alt="Chatting" 
											:src="'/images/Chatting.png'"
										>
									</div>
									<h5 class="card-title">
										Chatting
									</h5>
									<p class="card-text text-height">
										Chat with your doctor anytime.
									</p>
								</div>
							</div>
						</div>
					</template>
					<template #calling>
						<div class="col ms-2 h-100">
							<div 
								class="card h-100 border-0 rounded-5"
							>
								<div class="card-body">
									<div class="text-center">
										<img
											class="img-fluid" 
											alt="Calling" 
											:src="'/images/Calling.png'"
										>
									</div>
									<h5 class="card-title">
										Calling
									</h5>
									<p class="card-text text-height">
										Schedule follow up appointment any time during your treatment.
									</p>
								</div>
							</div>
						</div>
					</template>
					<template #reminder>
						<div class="col ms-2 h-100">
							<div 
								class="card h-100 border-0 rounded-5"
							>
								<div class="card-body">
									<div class="text-center">
										<img
											class="img-fluid" 
											alt="Reminder" 
											:src="'/images/Reminder.png'"
										>
									</div>
									<h5 class="card-title">
										Reminder
									</h5>
									<p class="card-text text-height">
										Personalized skincare routine delivered to your door step.
									</p>
								</div>
							</div>
						</div>	
					</template>
				</CarouselV1>
			</div>
		</div>
	</div>	
</template>

<script>
import CarouselV1 from '@/common/components/carousel/Carousel.vue';
import gsap from '@/config/gsapEffects'
export default {
	name: "WhatToExpectSection",
	components:{
		CarouselV1,
	},
	data(){
		return {
			cardNames:['chatting','calling','reminder']
			
		}
	},
	mounted(){
		gsap.effects.fadeIn("#what-to-expect-cards .card", {duration: 1.5, stagger: 0.4, scrollTrigger: {
			trigger: "#what-to-expect-section",
			start: "top 40%",
		}})

	}
}
</script>
