export default class GoogleAuthConfigs {
	constructor(configs) {
		this.googleClientId = configs.VUE_APP_GOOGLE_CLIENT_ID;
		this.googleClientSecret = configs.VUE_APP_GOOGLE_CLIENT_SECRET;
		this.googleRedirectUri = configs.VUE_APP_GOOGLE_REDIRECT_URI;
		this.googleResponseType = configs.VUE_APP_GOOGLE_RESPONSE_TYPE;
		this.googleScope = configs.VUE_APP_GOOGLE_SCOPE;
		this.googleOauthIdTokenURL = configs.VUE_APP_GOOGLE_OAUTH_TOKEN_URI;
		this.googleAuthorizationURL =
			`${configs.VUE_APP_GOOGLE_AUTHORIZATION_URL_BASE}?` +
			`scope=${encodeURIComponent(this.googleScope)}&` +
			`client_id=${this.googleClientId}&` +
			`redirect_uri=${encodeURIComponent(this.googleRedirectUri)}&` +
			`response_type=${this.googleResponseType}&` 
	}

	getGoogleAuthCode(state) {
		window.location.href = this.googleAuthorizationURL + `state=${state}`;
	}

}
