import { library } from '@fortawesome/fontawesome-svg-core'
import { 
	faBookmark as farBookmark, 
	faHeart as farHeart, 
	faStar, 
	faCircle,
	faCircleDot,
} from '@fortawesome/free-regular-svg-icons'
import {
	faAngleDown,
	faMedal,
	faThumbsDown,
	faUserGear,
	faEyeSlash,
	faEye,
	faCircleCheck,
	faCircleArrowRight,
	faCircleChevronRight,
	faCircleChevronLeft,
	faCircleXmark,
	faCircleInfo,
	faPen,
	faArrowLeft,
	faGripLines,
	faCheck,
	faChevronDown,
	faMinus,
	faPlus,
	faMagnifyingGlass,
	faShareNodes,
	faBookmark,
	faHeart,
	faIndianRupeeSign,
	faCommentDots,
	faTruck,
	faThumbsUp,
	faCircleNotch,



} from '@fortawesome/free-solid-svg-icons';

library.add(
	faAngleDown,
	faMedal,
	faUserGear,
	faThumbsDown,
	faEyeSlash,
	faCircleChevronRight,
	faCircleChevronLeft,
	faEye,
	faCircleCheck,
	faCircleXmark,
	faCircleInfo,
	faCircleArrowRight,
	faPen,
	faArrowLeft,
	faGripLines,
	faCheck,
	faChevronDown,
	faMinus,
	faPlus,
	faGripLines,
	faHeart,
	faShareNodes,
	faBookmark,
	farHeart,
	farBookmark,
	faIndianRupeeSign,
	faCommentDots,
	faStar,
	faTruck,
	faThumbsUp,
	faCircleNotch,
	faCircle,
	faCircleDot,
	faMagnifyingGlass
);