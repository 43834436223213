<template>
	<div class="mb-4">
		<IconSection name="sp-top" />
		<div
			id="specialization-section"
			class="p-sm-4 p-3"
			style="background-color: #FFD37B"
		>
			<div class="row text-center">
				<h2>Our Specialization</h2>
			</div>
			<div class="row m-sm-4 m-2">
				<div class="col-4 col-sm-2 p-2  specialize-item">
					<div class="d-flex justify-content-center py-3">
						<img 
							loading="lazy"
							class="img-fluid" 
							alt="acne" 
							src="/images/acne.png" 
						>
					</div>
					<h5 class="text-center">
						Acne
					</h5>
				</div>
				<div class="col-4 col-sm-2 p-2 specialize-item">
					<div class="col d-flex justify-content-center py-3">
						<img 
							loading="lazy"
							class="img-fluid" 
							alt="pores" 
							src="/images/pores.png" 
						>
					</div>
					<h5 class="text-center">
						Clogged Pores
					</h5>
				</div>
				<div class="col-4 col-sm-2 p-2 specialize-item">
					<div class="col d-flex justify-content-center py-3">
						<img 
							loading="lazy"
							class="img-fluid" 
							alt="dark-spots" 
							src="/images/dark_spots.png" 
						>
					</div>
					<h5 class="text-center">
						Dark Spots
					</h5>
				</div>
				<div class="col-4 col-sm-2 p-2 specialize-item">
					<div class="col d-flex justify-content-center py-3">
						<img 
							loading="lazy"
							class="img-fluid" 
							alt="finelines" 
							src="/images/finelines.png"
						>
					</div>

					<h5 class="text-center">
						Finelines
					</h5>
				</div>
				<div class="col-4 col-sm-2 p-2 specialize-item">
					<div class="col d-flex justify-content-center py-3">
						<img 
							loading="lazy"
							class="img-fluid" 
							alt="rosacea" 
							src="/images/rosacea.png" 
						>
					</div>
					<h5 class="text-center">
						Rosacea
					</h5>
				</div>
				<div class="col-4 col-sm-2 p-2 specialize-item">
					<div class="d-flex justify-content-center py-3">
						<img 
							loading="lazy"
							class="img-fluid" 
							alt="wrinkles" 
							src="/images/wrinkles.png" 
						>
					</div>
					<h5 class="text-center">
						Wrinkles
					</h5>
				</div>
			</div>
		</div>
		<IconSection name="sp-bottom" />
	</div>
</template>
<script>
import IconSection from '@/common/svgs/icons.vue'
import gsap from '@/config/gsapEffects'
export default {
	name: "SpecializationSection",
	components: {
		IconSection
	},
	mounted() {

		gsap.effects.fadeIn(".specialize-item", {duration: 1.5, scrollTrigger: {
			trigger: "#specialization-section",
			start: "top 80%",
		}, stagger: 0.2})



	}
}
</script>
<style>
.sp {
	padding-top: 33%;
}
</style>