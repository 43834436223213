<template>
	<div class="container-lg my-5">
		<div class="row py-5">
			<h2 class="d-flex justify-content-start">
				Reviews
			</h2>
		</div>
		<div id="reviewsectionid">
			<CarouselV1
				class="row"
				:card-names="cardNames"
				:show-direction-controls="false"
				:pagination="{ clickable: true }"
				:navigation="true"
				:pagination-clickable="true"
				:slides-per-view="1"
			>
				<template #Review1>
					<div class="col">
						<div 
							class="card h-100 rounded-5 p-3"
						>
							<div class="card-body mb-5">
								<p class="card-text text-height">
									“Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat.”
								</p>
							</div>
							<div class="card-footer d-flex align-items-center py-3">
								<img 
									class="img-fluid col-4 object-fit-cover" 
									alt="Author" 
									src="/images/Author.png" 
								>
								<div class="col text-center">
									<h4>Author Name</h4>
									<p>VP of Company</p>
								</div>
							</div>
						</div>
					</div>
				</template>
				<template #Review2>
					<div class="col">
						<div 
							class="card h-100 rounded-5 p-3"
						>
							<div class="card-body mb-5">
								<p class="card-text text-height">
									“Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat.”
								</p>
							</div>
							<div class="card-footer d-flex align-items-center py-3">
								<img 
									class="img-fluid col-4 object-fit-cover" 
									alt="Author" 
									src="/images/Author.png" 
								>
								<div class="col text-center">
									<h4>Author Name</h4>
									<p>VP of Company</p>
								</div>
							</div>
						</div>
					</div>
				</template>
				<template #Review3>
					<div class="col">
						<div 
							class="card h-100 rounded-5 p-3"
						>
							<div class="card-body mb-5">
								<p class="card-text text-height">
									“Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat.”
								</p>
							</div>
							<div class="card-footer d-flex align-items-center py-3">
								<img 
									class="img-fluid col-4 object-fit-cover" 
									alt="Author" 
									src="/images/Author.png" 
								>
								<div class="col text-center">
									<h4>Author Name</h4>
									<p>VP of Company</p>
								</div>
							</div>
						</div>
					</div>
				</template>
				<template #Review4>
					<div class="col">
						<div 
							class="card h-100 rounded-5 p-3"
						>
							<div class="card-body mb-5">
								<p class="card-text text-height">
									“Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat.”
								</p>
							</div>
							<div class="card-footer d-flex align-items-center py-3">
								<img 
									class="img-fluid col-4 object-fit-cover" 
									alt="Author" 
									src="/images/Author.png" 
								>
								<div class="col text-center">
									<h4>Author Name</h4>
									<p>VP of Company</p>
								</div>
							</div>
						</div>
					</div>
				</template>
			</CarouselV1>
		</div>
	</div>
</template>

<script>
import CarouselV1 from '@/common/components/carousel/Carousel.vue';

export default {
	name: "ReviewsSection",
	components:{
		CarouselV1
	},
	data(){
		return {
			cardNames:['Review1','Review2','Review3','Review4']
		}
	},
}
</script>
<style>

</style>