<template>
	<div
		id="cart__section"
		class="container-lg p-5"
	>
		<h3>
			Review Your Cart
		</h3>
		<div class="row mt-5 justify-content-between gap-5">
			<div class="col-12 col-md-auto">
				<label
					for="cartCouponInput"
					class="form-label fs-5"
				>Apply Coupon</label>
				<div class="position-relative">
					<input
						id="cartCouponInput"
						v-model="couponCode"
						class="form-control"
						:class="{'is-invalid': v$.$invalid}"
						placeholder="Enter Coupon Code" 
					>
					<font-awesome-icon
						v-if="!v$.$invalid"
						:icon="['fas', 'circle-arrow-right']" 
						size="lg"
						class="arrow__icon position-absolute top-50 end-0 translate-middle text-secondary"
					/>
					<div class="invalid-feedback">
						Only alphanumeric characters are accepted.
					</div>
				</div>
				<div class="text-secondary my-3">
					<font-awesome-icon 
						:icon="['fas', 'check']" 
						class="me-2"
						style="color: var(--yellow-secondary-color-variant);" 
					/>
					Delivery by {{ cart.deliveryBy }}
				</div>
				<div class="d-flex justify-content-between">
					<span>
						<font-awesome-icon 
							:icon="['fas', 'circle-info']"
							class="me-2"
						/>
						Estimated Savings
					</span>
					<span>₹{{ cart.totalSavings }}</span>
				</div>
				<div class="d-flex justify-content-between border-top border-3 mt-3 py-2">
					<h5>Total</h5>
					<h5>₹{{ cart.totalAmount }}</h5>
				</div>
				<button
					class="w-100 rounded-pill border-0 fs-5 py-1"
					style="background-color: var(--yellow-secondary-color-variant);"
				>
					Proceed to Checkout
				</button>
			</div>
			<div class="col-12 col-md-auto">
				<div
					v-for="(product, productIndex) in cart.products"
					:key="productIndex" 
					class="mb-4 position-relative"
				>
					<button 
						class="btn position-absolute top-0 end-0"
						style="translate: 100%;"
						@click="() => cart.products.splice(productIndex, 1)"
					>
						<font-awesome-icon :icon="['fas', 'circle-xmark']" />
					</button>
					<div 
						class="row align-items-center justify-content-md-between g-3"
					>
						<div class="col-auto">
							<img 
								class="rounded object-fit-cover"
								style="width: 5rem; aspect-ratio: 1 / 1;"
								:src="product.image"
							>
						</div>
						<div class="col-auto d-flex flex-column">
							<h6>
								<span class="badge me-1 bg-secondary fw-normal">
									{{ product.size }}
								</span>
								{{ product.name }}
							</h6>
							<h6>
								MRP : 
								<span 
									v-if="product.savings" 
									class="text-decoration-line-through me-2"
								>
									₹{{ product.price }}
								</span>
								₹{{ product.price - ( product.savings || 0 ) }}
							</h6>
						</div>
					</div>
					<div class="row mt-2 justify-content-end">
						<div class="col-auto">
							<div 
								class="d-inline-block rounded border border-1"
								style="background-color: #fff8eb; color: #e5991a; border-color: #e5991a !important;"
							>
								<button 
									class="btn border-0 text-reset"
									@click="() => product.quantity > 1 && product.quantity--"
								>
									<font-awesome-icon :icon="['fas', 'minus']" />
								</button>
								{{ product.quantity }}
								<button 
									class="btn border-0 text-reset"
									@click="() => product.quantity++"
								>
									<font-awesome-icon :icon="['fas', 'plus']" />
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Cart from '@/CartPage/models/Cart';
import { useVuelidate } from '@vuelidate/core'
import { alphaNum } from '@vuelidate/validators'
export default {	
	name: "CartSection",
	setup: () => ({ v$: useVuelidate() }),
	data() {
		return {
			couponCode: null,
			cart: new Cart({
				totalSavings: 100, 
				totalAmount: 499,
				deliveryBy: "15 May, 2024", 
				products: [
					{
						name: "AHA/BHA Foaming Cleanser",
						image: "images/product_cleanser_v1.png",
						quantity: 1,
						size: "50ml",
						price: 499,
						savings: 100
					},
					{
						name: "AHA/BHA Foaming Cleanser",
						image: "images/product_cleanser_v1.png",
						quantity: 1,
						size: "50ml",
						price: 499,
					}
				]
			})
		}
	},
	validations: () => ({ couponCode : { alphaNum } })
}
</script>
<style>
#cartCouponInput:placeholder-shown + .arrow__icon {
	display: none;
}
</style>