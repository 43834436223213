<template>
	<div class="container-lg">
		<div v-if="product">
			<div
				id="SingleProductPage"
				class="row py-4 h-100"
			>
				<div
					id="carousel-container"
					class="col-12 col-sm-6 h-100 overflow-hidden"
				>
					<CarouselV2 
						class="carousel" 
						:product="product"
					/>
				</div>
				<div class="col-12 col-sm-6 h-100 product-text-container pt-md-0 pt-5">
					<div class="row">
						<div class="col-12">
							<h3>
								{{ product.product_name }}
							</h3>
							<h5 class="text-success">
								{{ product.product_actives }}
							</h5>
							<h5 class="text-success">
								{{ product.product_skin_type }}
							</h5>
						</div>
					</div>
					<div class="py-4">
						<div class="border-2 border-top p-1" />
					</div>
					<div class="row d-flex justify-content-center">
						<div class="col-lg-7 col-12">
							<h4 class="d-flex gap-2 justify-content-center">
								<span>MRP:</span>
								<span class="text-decoration-line-through">
									<font-awesome-icon :icon="['fas', 'indian-rupee-sign']" /> 
									{{ product.product_price }}
								</span>
								<span>
									<font-awesome-icon :icon="['fas', 'indian-rupee-sign']" />
									{{ product.product_discounted_price }}
								</span>
							</h4>
						</div>
					</div>
					<div class="py-3">
						<div class="border-2 border-top p-1" />
					</div>
					<div>
						<h5>Description</h5>
						<p class="fs-5">
							{{ product.product_short_description }}
						</p>
					</div>
				</div>
			</div>
			<div class="row my-5 text-center">
				<h4>
					Benefits
				</h4>
			</div>

			<div class="row mb-5">
				<div
					v-for="(image, index) in product.product_benefits"
					:key="index"
					class="col-6 col-sm-3 text-center py-3"
				>
					<img
						:src="image.icon_url"
						:alt="product.product_name"
					>
					<h6 class="pt-3 text-break">
						{{ image.icon_name }}
					</h6>
				</div>
			</div>
			<div class="row justify-content-center">
				<div class="col-12 col-xl-10">
					<NavigativeElements 
						:update-route="updateRoute"
						:tabs="tabData" 
						:initial-tab="initialTab"
						:product="product"
					/>
				</div>
			</div>
		</div>
		<div
			v-else-if="loading"
			class="text-center my-5"
		>
			<font-awesome-icon
				:icon="['fas', 'circle-notch']"
				size="3x"
			/>
			<p>Loading...</p>
		</div>
		<div v-else>
			<ErrorAlert 
				:message="message"
			/>
		</div>
	</div>
</template>
<script>
import CarouselV2 from '@/common/components/carousel/CarouselV2.vue';
import NavigativeElements from '@/common/components/NavigativeElements.vue';
import ReviewsPage from './ReviewsPage.vue';
import DescriptionPage from './DescriptionPage.vue';
import gsap from '@/config/gsapEffects';
import AllProductsService from '@/Products/services/AllProductsService';
import ErrorAlert from '@/common/components/ErrorAlert.vue';
export default {
	components:{
		CarouselV2,
		NavigativeElements,
		ErrorAlert
	},
	props: {
		productId: {
			type: String,
			required: true
		}
	},
	data(){
		return{
			loading:true,
			product: {product_name: ""},
			updateRoute: false,
			message: '',
			tabData:[
				{
					name: 'Description',
					title: 'Description',
					component: DescriptionPage,
				},
				{
					name: 'Reviews',
					title: 'Reviews',
					component: ReviewsPage,
					
				}
			]
		}
	},
	computed: {
		initialTab() {
			return  'Description';
		}
	},
	watch:{
		productId:'fetchProductData',
	},
	mounted(){
		this.fetchProductData();
	},
	methods:{
		registerGsap(){
			let mm = gsap.matchMedia()
		
			mm.add("(min-width: 768px)", () => {
				gsap.timeline({
					scrollTrigger: {
						trigger: "#SingleProductPage",
						start: "top 15%",
						end: "bottom bottom",
						pin: "#carousel-container",
						pinSpacing:true
					},
					delay: 1
				})
			})
		},
		fetchProductData() {
			AllProductsService.getAllProducts()
				.then(response => {
					const productData = response.data.find(p =>
						p.product_id === this.productId);
					
					if(productData){
						this.product=productData
						this.registerGsap()
					}
					else{
						this.product = null;
						this.message=`Product ${this.productId} is not available`
					}
				})
				.catch(err => {
					err.message=`Unable to found any product `
				})
				.finally(()=>{
					this.loading=false;
				});
		},
	}

}
</script>
<style>

</style>