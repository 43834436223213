<template>
	<div class="container">
		<div class="row">
			<h5>Product Description</h5>
		</div>
		<div class="row p-3">
			<p>
				{{ product.product_long_description }}
			</p>
		</div>
	</div>
</template>
<script>
export default {
	props:{
		product:{
			type:Object,
			required:true,
		}
	},
}
</script>
<style lang="">
	
</style>