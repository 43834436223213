<template>
	<HeroSection />
	<SpecializationSection />
	<StepsSection />
	<WhatToExpectSection />
	<TreatmentWorksSection />
	<PricingSection />
	<!-- <SkincareEssentials /> -->
</template>
<script>
import HeroSection from '@/HomePage/components/HeroSection.vue';
import PricingSection from '@/HomePage/components/PricingSection.vue';
import WhatToExpectSection from '@/HomePage/components/WhatToExpectSection.vue'
import TreatmentWorksSection from '@/HomePage/components/TreatmentWorksSection.vue'
import SpecializationSection from '@/HomePage/components/SpecializationSection.vue'
import StepsSection from '@/HomePage/components/StepsSection.vue'
// import SkincareEssentials from '@/HomePage/components/SkincareEssentials.vue'
export default {
	name: "HomePage",
	components: {
		HeroSection,
		SpecializationSection,
		WhatToExpectSection,
		TreatmentWorksSection,
		PricingSection,
		StepsSection,
		// SkincareEssentials,
		
	},
}
</script>
<style>
</style>